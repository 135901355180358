import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import firebase from "../../config/firebase.js";
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { useForm } from 'react-hook-form';
import EmbedForm from "../EmbedForm/EmbedForm";
import styled from 'styled-components';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faFileInvoiceDollar, faPen, faTrashAlt, faFileExport } from "@fortawesome/free-solid-svg-icons";
import ListGroup from 'react-bootstrap/ListGroup'

import _ from "lodash";

import "./AdminStripePayoutsPending.min.css";

library.add(faFileInvoiceDollar, faPen, faTrashAlt, faFileExport);

const { ExportCSVButton } = CSVExport;
const { SearchBar } = Search;

const TEST_LOCAL = false;

function AdminStripePayoutsPending(props) {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [paymentIDsToSend, setPaymentIDsToSend] = useState([]);
  const [paymentIDsToEdit, setPaymentIDsToEdit] = useState([]);
  const [paymentIDsToDelete, setPaymentIDsToDelete] = useState([]);

  const actionsFormatter = (cell, row) => (
      <>
        <OverlayTrigger
          key='send-payment-key'
          placement='bottom'
          overlay={
            <Tooltip id={`tooltip-bottom`}>
              Send payment via Stripe
            </Tooltip>
          }
        >
          <ActionButton variant="success" onClick={() => { handleClickedSendPaymentForRow(row.id); }}><ButtonIcon icon="file-invoice-dollar" /></ActionButton>
        </OverlayTrigger>
        <OverlayTrigger
          key='edit-payment-key'
          placement='bottom'
          overlay={
            <Tooltip id={`tooltip-bottom`}>
              Edit Payment
            </Tooltip>
          }
        >
          <ActionButton variant="secondary" onClick={() => { handleClickedEditPaymentForRow(row.id); }}><ButtonIcon icon="pen" /></ActionButton>
        </OverlayTrigger>
        <OverlayTrigger
          key='cancel-payment-key'
          placement='bottom'
          overlay={
            <Tooltip id={`tooltip-bottom`}>
              Delete pending payment
            </Tooltip>
          }
        >
          <ActionButton variant="danger" onClick={() => { handleClickedDeletePaymentForRow(row.id); }}><ButtonIcon icon="trash-alt" /></ActionButton>
        </OverlayTrigger>
      </>
);

  const renderMultiSelectActionButtons = () => (
    <>
    <div style={{alignSelf: 'center', marginRight: 12}}>With selected payments: </div>
      <OverlayTrigger
        key='send-payment-key'
        placement='bottom'
        overlay={
          <Tooltip id={`tooltip-bottom`}>
            Send payments for selected payouts via Stripe
          </Tooltip>
        }
      >
        <ActionButton variant="success" onClick={() => { handleClickedSendPaymentForSelectedRows(); }} disabled={(selectedRows && _.isArray(selectedRows) && selectedRows.length > 0 ? '' : 'disabled')}><ButtonIcon icon="file-invoice-dollar" /> Send $</ActionButton>
      </OverlayTrigger>
      <OverlayTrigger
        key='edit-payment-key'
        placement='bottom'
        overlay={
          <Tooltip id={`tooltip-bottom`}>
            Edit payments for selected payouts
          </Tooltip>
        }
      >
        <ActionButton variant="secondary" onClick={() => { handleClickedEditPaymentForSelectedRows(); }} disabled={(selectedRows && _.isArray(selectedRows) && selectedRows.length === 1 ? '' : 'disabled')}><ButtonIcon icon="pen" /> Edit</ActionButton>
      </OverlayTrigger>
      <OverlayTrigger
        key='cancel-payment-key'
        placement='bottom'
        overlay={
          <Tooltip id={`tooltip-bottom`}>
            Delete pending payments for selected payouts
          </Tooltip>
        }
      >
        <ActionButton variant="danger" onClick={() => { handleClickedDeletePaymentForSelectedRows(); }} disabled={(selectedRows && _.isArray(selectedRows) && selectedRows.length > 0 ? '' : 'disabled')}><ButtonIcon icon="trash-alt" /> Delete</ActionButton>
      </OverlayTrigger>
    </>
  )

  const handleClickedSendPaymentForRow = (row_id) => {
    console.log('here');
    setSelectedRows([]);
    setPaymentIDsToSend([row_id]);
    setPaymentIDsToEdit([]);
    setPaymentIDsToDelete([]);
    setShowConfirmModal(true);
  }

  const handleClickedEditPaymentForRow = (row_id) => {
    setSelectedRows([]);
    setPaymentIDsToSend([]);
    setPaymentIDsToEdit([row_id]);
    setPaymentIDsToDelete([]);
    setShowConfirmModal(true);
  }

  const handleClickedDeletePaymentForRow = (row_id) => {
    setSelectedRows([]);
    setPaymentIDsToSend([]);
    setPaymentIDsToEdit([]);
    setPaymentIDsToDelete([row_id]);
    setShowConfirmModal(true);
  }

  const handleClickedSendPaymentForSelectedRows = () => {
    setPaymentIDsToSend(selectedRows);
    setPaymentIDsToEdit([]);
    setPaymentIDsToDelete([]);
    setShowConfirmModal(true);
  }

  const handleClickedEditPaymentForSelectedRows = () => {
    setPaymentIDsToSend([]);
    setPaymentIDsToEdit(selectedRows);
    setPaymentIDsToDelete([]);
    setShowConfirmModal(true);
  }

  const handleClickedDeletePaymentForSelectedRows = () => {
    setPaymentIDsToSend([]);
    setPaymentIDsToEdit([]);
    setPaymentIDsToDelete(selectedRows);
    setShowConfirmModal(true);
  }

  const handleCloseConfirmModal = () => {
    setPaymentIDsToSend([]);
    setShowConfirmModal(false);
  }

  const columns= [
    {
      text: 'User',
      dataField: 'user_name',
      sort: true
    },
    {
      text: 'Lead',
      dataField: 'lead_title',
      sort: true
    },
    {
      text: 'Stripe Account',
      dataField: 'account_id'
    },
    {
      text: 'Amount',
      dataField: 'amount',
      sort: true
    },
    {
      text: 'Created',
      dataField: 'date',
      sort: true
    },
    {
      dataField: 'actions',
      text: 'Actions',
      isDummyField: true,
      csvExport: false,
      formatter: actionsFormatter
    }
  ];


  const handleOnSelect = (row, isSelect) => {
    console.log(row);
    console.log(isSelect);
    var currentlySelected = selectedRows;
      if (isSelect) {
        setSelectedRows([...selectedRows, row.id]);
      } else {
        setSelectedRows(selectedRows.filter(x => x !== row.id));
      }
    }

  const handleOnSelectAll = (isSelect, rows) => {
      const ids = rows.map(r => r.id);
      console.log(isSelect,ids);
      if (isSelect) {
        setSelectedRows(ids);
      } else {
        setSelectedRows([]);
      }
    }



  const selectRow = {
    mode: 'checkbox',
    clickToSelect: false,
    selected: selectedRows,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll
  };

  const [payoutsPending, setPayoutsPending] = useState(
    [
      {
        id: "fake_user_id/another_lead_id",
        components: [
          {
            id: "auto_id",
            amount: 100
          }
        ],
        user_id: "fake_user_id",
        lead_id: "another_lead_id",
        amount: 100,
        user_name: "John",
        lead_title: "cool lead bro",
        createdAt: 1618545068724,
        date: "4/08/2021",
        account_id: "lkasdjflkjasdf"
      },
      {
        id: "n6WGWUXIC2XnARgqYljTSNXcVDu2/-MW_iOKJWxTkkHx1-cfz",
        components: [
          {
            id: "-MYNnPDUq9x7b0zRvh0f",
            amount: 123
          },
          {
            id: "-MYQ-dzKOe_wnldyev5E",
            amount: 20
          }
        ],
        user_id: "n6WGWUXIC2XnARgqYljTSNXcVDu2",
        lead_id: "-MW_iOKJWxTkkHx1-cfz",
        amount: 143,
        user_name: "Chris Hayeser",
        lead_title: "NewQuote52 User - Cromell, CT",
        createdAt: 1618545058724,
        date: "4/10/2021",
        account_id: "acct_1Ifdlx2azD7dKUvD"
      },
      {
        id: "n6WGWUXIC2XnARgqYljTSNXcVDu2/fake_lead_id",
        components: [
          {
            id: "fake_auto_id",
            amount: 60
          }
        ],
        user_id: "n6WGWUXIC2XnARgqYljTSNXcVDu2",
        lead_id: "fake_lead_id",
        amount: 60,
        user_name: "Chris Hayes",
        lead_title: "fake title",
        createdAt: 1618543058724,
        date: "4/12/2021",
        account_id: "acct_1l"
      }
    ]
);

  useEffect(() => {
    console.log(TEST_LOCAL);
    if (!TEST_LOCAL) {
      try {
        var result = loadPendingPayoutsData();
        console.log(result);
      } catch (e) {
        console.log('got an error loading data');
        console.error(e.message);
      } finally {
        console.log('finally done with useEffect');
      }
      return unsubscribeToPendingPayoutsData();
    }
  }, []);

  const loadPendingPayoutsData = async () => {
    console.log('loadPendingPayoutsData');
    try {
      console.log('before');
      firebase.database().ref('stripe_pending_payouts').on('value', (snapshot) => {
        var pendingPayouts = [];
        if (snapshot.exists()) {
          // snapshotVal: Get all pending data payouts for all users.
          // Keys are user ids
          var snapshotVal = snapshot.val();
          if (snapshotVal && _.isObject(snapshotVal)) {

            // pendingPayoutUserIDs: Array of user_ids who are owed a payout
            var pendingPayoutUserIDs = Object.keys(snapshotVal);
            if (pendingPayoutUserIDs && _.isArray(pendingPayoutUserIDs) && pendingPayoutUserIDs.length > 0) {
              pendingPayoutUserIDs.forEach((pendingPayoutUserID, ppui) => {

                // pendingPayoutUserLeadsDict: dictionary for an individual user of all the leads where a payout is owed
                var pendingPayoutUserLeadsDict = snapshotVal[pendingPayoutUserID];
                if (pendingPayoutUserLeadsDict && _.isObject(pendingPayoutUserLeadsDict)) {

                  // pendingPayoutUserLeadIDs: array of all leads for an individual user where payouts are due
                  var pendingPayoutUserLeadIDs = Object.keys(pendingPayoutUserLeadsDict);
                  if (pendingPayoutUserLeadIDs && _.isArray(pendingPayoutUserLeadIDs) && pendingPayoutUserLeadIDs.length > 0) {
                    pendingPayoutUserLeadIDs.forEach((pendingPayoutUserLeadID, ppuli) => {
                      var payoutDetailsForLead = {};

                      // pendingPayoutUserLeadPayoutsDict: dictionary of all payout components for a lead where payout is due
                      var pendingPayoutUserLeadPayoutsDict = pendingPayoutUserLeadsDict[pendingPayoutUserLeadID];
                      if (pendingPayoutUserLeadPayoutsDict && _.isObject(pendingPayoutUserLeadPayoutsDict)) {

                        // pendingPayoutUserLeadPayoutAutoIDs: array of auto ids of payout components
                        var pendingPayoutUserLeadPayoutAutoIDs = Object.keys(pendingPayoutUserLeadPayoutsDict);
                        if (pendingPayoutUserLeadPayoutAutoIDs && _.isArray(pendingPayoutUserLeadPayoutAutoIDs) && pendingPayoutUserLeadPayoutAutoIDs.length > 0) {
                          pendingPayoutUserLeadPayoutAutoIDs.forEach((pendingPayoutUserLeadPayoutAutoID, ppulpai) => {

                            // individual payout component details for payout for lead for user
                            var payoutDict = pendingPayoutUserLeadPayoutsDict[pendingPayoutUserLeadPayoutAutoID];
                            if (payoutDict && _.isObject(payoutDict)) {
                              if (payoutDict.payout && _.isObject(payoutDict.payout)) {
                                if (payoutDetailsForLead.amount && _.isFinite(payoutDetailsForLead.amount) && payoutDetailsForLead.amount > 0) {
                                  payoutDetailsForLead.amount += payoutDict.payout.amount;
                                  payoutDetailsForLead.components.push({id: pendingPayoutUserLeadPayoutAutoID, amount: payoutDict.payout.amount });
                                } else {
                                  var d = new Date(payoutDict.createdAt);
                                  payoutDetailsForLead = {
                                    id: pendingPayoutUserID + '/' + pendingPayoutUserLeadID,
                                    components: [{id: pendingPayoutUserLeadPayoutAutoID, amount: payoutDict.payout.amount }],
                                    user_id: pendingPayoutUserID,
                                    lead_id: pendingPayoutUserLeadID,
                                    amount: payoutDict.payout.amount,
                                    user_name: payoutDict.user_details.name,
                                    lead_title: payoutDict.title,
                                    createdAt: payoutDict.createdAt,
                                    date: d.toLocaleDateString("en-US"),
                                    account_id: payoutDict.account_id
                                  }
                                }
                              }
                            }
                          });
                        }
                      }

                      pendingPayouts.push(payoutDetailsForLead);
                    });
                  }
                }
              });
            }
          }
        }
        setPayoutsPending(pendingPayouts);
      });
      console.log('after');
    } catch (e) {
      console.log('an error occurred');
      console.log(e.message);
    } finally {
      console.log('finally');
    }

    // try {
    //   var stripePayoutsPendingCallable = firebase.functions().httpsCallable('stripePayoutsPendingCallable');
    //   setPayoutsPending(await stripePayoutsPendingCallable());
    //
    // } catch (e) {
    //
    //   console.log('error');
    //     console.log(e.message);
    // } finally {
    //   console.log('finally');
    // }

  }

  const unsubscribeToPendingPayoutsData = () => {
    firebase.database().ref('go').off('value');
  }

  const renderSendPaymentModal = () => {
    var payments = [];
    if (paymentIDsToSend && _.isArray(paymentIDsToSend) && paymentIDsToSend.length > 0) {
      payments = rowsForIds(paymentIDsToSend);
    }
    var payments = rowsForIds(paymentIDsToSend);

    if (!(payments && _.isArray(payments) && payments.length > 0)) {
      return (
        <Modal show={showConfirmModal} onHide={handleCloseConfirmModal}>
          <Modal.Header closeButton>
            <Modal.Title>Error</Modal.Title>
          </Modal.Header>
          <Modal.Body>There was an error retrieving the payments</Modal.Body>
          <Modal.Footer>
            <ActionButton variant="secondary" onClick={handleCloseConfirmModal}>
              Close
            </ActionButton>
          </Modal.Footer>
        </Modal>
      );
    }

    const listRows = payments.map((payment) => {
      return ( <ListGroup.Item key={payment.id} ><b>[${payment.amount}]</b> {payment.user_name} -- {payment.lead_title}</ListGroup.Item> )
    });

    const paymentTotal = (payments) => {
      return payments.reduce((a,b) => a + (b.amount || 0), 0);
    }

    return (
      <Modal show={showConfirmModal} onHide={handleCloseConfirmModal}>
        <Modal.Header closeButton>
          <Modal.Title>Send payment{payments && _.isArray(payments) && payments.length > 1 ? 's' : ''}?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Would you like to send the payment{payments && _.isArray(payments) && payments.length > 1 ? 's' : ''} for all of the following transactions:<br/>
          <ListGroup>
            {listRows}
          </ListGroup>
          { payments && _.isArray(payments) && payments.length > 1 && (
          <div><b>${paymentTotal(payments)} Total</b> will be released</div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <ActionButton variant="danger" onClick={handleCloseConfirmModal}>
            Cancel
          </ActionButton>
          <ActionButton variant="success" onClick={handleConfirmSendPayment}>
            Send Payment{payments && _.isArray(payments) && payments.length > 1 ? 's' : ''}
          </ActionButton>
        </Modal.Footer>
      </Modal>
    )
  };

  const renderEditPaymentModal = () => (
    <Modal show={showConfirmModal} onHide={handleCloseConfirmModal}>
      <Modal.Header closeButton>
        <Modal.Title>Edit payment{paymentIDsToEdit && _.isArray(paymentIDsToEdit) && paymentIDsToEdit.length > 1 ? 's' : ''}?</Modal.Title>
      </Modal.Header>
      <Modal.Body>Would you like to edit the payment {paymentIDsToEdit}?</Modal.Body>
      <Modal.Footer>
        <ActionButton variant="danger" onClick={handleCloseConfirmModal}>
          Cancel
        </ActionButton>
        <ActionButton variant="secondary" onClick={handleConfirmEditPayment}>
          Edit Payment
        </ActionButton>
      </Modal.Footer>
    </Modal>
  );

  const renderDeletePaymentModal = () => (
    <Modal show={showConfirmModal} onHide={handleCloseConfirmModal}>
      <Modal.Header closeButton>
        <Modal.Title>Send payment{paymentIDsToDelete && _.isArray(paymentIDsToDelete) && paymentIDsToDelete.length > 1 ? 's' : ''}?</Modal.Title>
      </Modal.Header>
      <Modal.Body>Would you like to delete the payment to {paymentIDsToDelete}?<br/><br/><h3>This cannot be un-done.</h3></Modal.Body>
      <Modal.Footer>
        <ActionButton variant="secondary" onClick={handleCloseConfirmModal}>
          Cancel
        </ActionButton>
        <ActionButton variant="danger" onClick={handleConfirmDeletePayment}>
          Delete Payment
        </ActionButton>
      </Modal.Footer>
    </Modal>
  );

  const handleConfirmSendPayment = () => {
    console.log('send payments for '+JSON.stringify(paymentIDsToSend));
    setPaymentIDsToSend([]);
    handleCloseConfirmModal();
  }

  const handleConfirmEditPayment = () => {
    console.log('edit payments for '+JSON.stringify(paymentIDsToEdit));
    setPaymentIDsToEdit([]);
    handleCloseConfirmModal();
  }

  const handleConfirmDeletePayment = () => {
    console.log('send payments for '+JSON.stringify(paymentIDsToDelete));
    setPaymentIDsToDelete([]);
    handleCloseConfirmModal();
  }

  const renderModals = () => {
    if (!showConfirmModal) {
      return null;
    }
    if (paymentIDsToSend && _.isArray(paymentIDsToSend) && paymentIDsToSend.length > 0) {
      return renderSendPaymentModal();
    }
    if (paymentIDsToEdit && _.isArray(paymentIDsToEdit) && paymentIDsToEdit.length > 0) {
      return renderEditPaymentModal();
    }
    if (paymentIDsToDelete && _.isArray(paymentIDsToDelete) && paymentIDsToDelete.length > 0) {
      return renderDeletePaymentModal();
    }
  }

  const rowsForIds = (ids) => {
    var payouts = payoutsPending.filter ( row => ids.includes(row.id) );
    if (payouts && _.isArray(payouts) && payouts.length > 0) {
      console.log('rowsForIds('+JSON.stringify(ids)+')');
      console.info(payouts);
      return payouts;
    }
    return [];
  }

  const MyExportCSV = (props) => {
    const handleClickedExportSelectedRows = () => {
      var rowsToExport = prepareForExportCSV((selectedRows && _.isArray(selectedRows) && selectedRows.length > 0 ? selectedRows : payoutsPending.map(row => row.id)));
      console.log('export rows ');
      console.log(rowsToExport);
      // props.onExport();
    };
    return (
      <div>
        <button className="btn btn-primary" onClick={ handleClickedExportSelectedRows }><ButtonIcon icon="file-export"></ButtonIcon>Export {(selectedRows && _.isArray(selectedRows) && selectedRows.length === 1 ? '1 payout' : (selectedRows && _.isArray(selectedRows) && selectedRows.length > 1 ? selectedRows.length + ' payouts' : 'all payouts'))} as CSV</button>
      </div>
    );
  };

  const prepareForExportCSV = (data) => {
    return data;

  }


/*  return (
    <>
      <SelectedItemsActionsWrapper>
        <MyExportCSV { ...props.csvProps }>Export CSV!!</MyExportCSV>
        {renderMultiSelectActionButtons()}
      </SelectedItemsActionsWrapper>
      <BootstrapTable keyField='id' data={payoutsPending} columns={columns} selectRow={ selectRow } exportCSV />
      {
        renderModals()
      }
    </>
  )*/

  return (
    <ToolkitProvider
      keyField="id"
      data={ payoutsPending }
      columns={ columns }
      exportCSV={ {
        fileName: 'custom.csv',
        separator: '|',
        ignoreHeader: true,
        noAutoBOM: false
      } }
    >
      {
        props =>
        (
          <div>
            <SelectedItemsActionsWrapper>
              <MyExportCSV />
              <div style={{flex:1, flexBasis: '100%', maxWidth: 500, width: '100%'}}>
              <SearchBar style={{width: '100%'}} placeholder="Search for a user or lead"/>
              </div>
                <div>
              {renderMultiSelectActionButtons()}
              </div>
            </SelectedItemsActionsWrapper>
            <hr />
            <BootstrapTable keyField='id' data={payoutsPending} columns={columns} selectRow={ selectRow } />
              {
                renderModals()
              }
          </div>
        )
      }
    </ToolkitProvider>
  )
}

const SelectedItemsActionsWrapper = styled.div`
display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 40px 40px 8px;
`;

const ActionButton = styled(Button)`
  margin: 0px 4px;
`;

const ButtonIcon = styled(FontAwesomeIcon)`
margin: 0px 4px;
`;

export default AdminStripePayoutsPending;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { asyncisLogin } from '../../utils/userSession';
import { ReactComponent as AdminLogo } from '../../isp-admin-background.svg'

// const Wrapper = styled.div`
//   max-width: ${({ fullWidth }) => (fullWidth ? '100%' : '1170px')};
//   margin: ${({ centred }) => (centred ? '0px auto' : '0')};
// `;

const Wrapper = styled.div`
  max-width: calc(100vw - 100px);
  margin: 0px auto;
`;

const PrivateRoute = ({
  component: Component,
  centred = true,
  adminOnly = true,
  fullWidth = false,
  location,
  ...rest
}) => {
  const [isGettingLogin, setIsGettingLogin] = useState(true);
  const [isLogin, setIsLogin] = useState(false);

  useEffect(() => {
    setIsGettingLogin(true);
    getIsLoggedIn();
  }, [adminOnly]);

  const getIsLoggedIn = async () => {
    await asyncisLogin((isL) => { setIsLogin(isL); setIsGettingLogin(false)},{needsAdmin: adminOnly});
  }
  return (
    <Route
      {...rest}
      render={props =>
        isGettingLogin ? (
          <AdminLogo style={{maxWidth: '100vw', maxHeight: 'calc(100vh - 400px)', margin: '200px auto'}} />
          ) : (
          <>
            { isLogin ? (
              <Wrapper centred={centred} fullWidth={fullWidth}>
                <Component {...props} {...rest} />
              </Wrapper>
            ) : (
              <Redirect
                to={{
                  pathname: '/login',
                  state: { from: location.pathname },
                }}
              />
            )}
          </>
          )
      }
    />
  )
}

PrivateRoute.propTypes = {
  component: PropTypes.any,
  centred: PropTypes.bool,
  fullWidth: PropTypes.bool,
  adminOnly: PropTypes.bool,
  location: PropTypes.any,
};

export default PrivateRoute;

import React, { useState, useEffect } from "react";
import { NavLink, Link, useHistory } from "react-router-dom";
import firebase from "../../config/firebase.js";
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { CIPHER_SECRET_KEY, electricBillOptions } from "../../config/config.js";
import CryptoJS from 'crypto-js';
import ScaleLoader from "react-spinners/ScaleLoader";
import "./EmbedForm.min.css";
import DownloadLinks from "../DownloadLinks/DownloadLinks";
import CreateAccountCreateNewUserSelection from "../CreateAccountCreateNewUserSelection/CreateAccountCreateNewUserSelection";

import _ from "lodash";


function EmbedForm(props) {
  const { register, handleSubmit, errors, reset, watch, setError } = useForm(); // initialize the hook
  const [formStep, setFormStep] = useState(1);
  const [formType, setFormType] = useState('quote');
  const [isIframe, setIsIframe] = useState(false);
  const [defaultName, setDefaultName] = useState(null);
  const [defaultEmail, setDefaultEmail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [referrerId, setReferrerId] = useState(null);
  const [createAccountReferrer, setCreateAccountReferrer] = useState(null);
  const [createAccountReferred, setCreateAccountReferred] = useState(null);
  const [pathElectricBill, setPathElectricBill] = useState(null);
  const [createAccountCreateNewUserSelection, setCreateAccountCreateNewUserSelection] = useState(false);
  const [response, setResponse] = useState(null);
  const [redirectSuccess, setRedirectSuccess] = useState(false);
  const availableFormTypes = ['quote', 'advocate', 'createaccount'];

  const history = useHistory();

  const FORM_RESPONSE_SHOW_TIME = 8; // how long the form success message shows for

  useEffect(() => {
    console.log('');
    console.log('');
    console.log('');
    console.log('props');
    console.log(props);
    if (props && _.isObject(props) && _.isString(props.formType) && availableFormTypes.includes(props.formType.toLowerCase())) {
      setFormType(props.formType.toLowerCase());
    }
    if (props && _.isObject(props) && _.isBoolean(props.isIframe)) {
      setIsIframe(props.isIframe);
    }
    if (props && _.isObject(props) && _.isString(props.referrerId) && props.referrerId !== '') {
      setReferrerId(props.referrerId);
    }
    if (props && _.isObject(props) && _.isObject(props.createAccountReferred)) {
      setCreateAccountReferred(props.createAccountReferred);
    }
    if (props && _.isObject(props) && _.isObject(props.createAccountReferrer)) {
      setCreateAccountReferrer(props.createAccountReferrer);
    }
    if (props && _.isObject(props) && _.isBoolean(props.redirectSuccess)) {
      setRedirectSuccess(true);
    }
    if (props && _.isObject(props) && _.isString(props.defaultName)) {
      console.log('got default name '+props.defaultName);
      setDefaultName(props.defaultName);
    }
    if (props && _.isObject(props) && _.isString(props.defaultEmail)) {
      setDefaultEmail(props.defaultEmail);
    }
    if (props && _.isObject(props) && _.isString(props.pathElectricBill)) {
      setPathElectricBill(props.pathElectricBill);
    }
  }, [props.formType, props.isIframe, props.referrerId, props.createAccountReferred, props.createAccountReferrer, props.redirectSuccess, props.defaultName, props.defaultEmail, props.pathElectricBill]);


  const maxStep = () => {
    switch (formType) {
      case 'createaccount':
        return 3; // Don't actually use this
        // 1 = terms
        // 2 = update password
        // 3 =
      case 'advocate':
        return 2;
      case 'quote':
      default:
        return 2;
    }
  }

  const createUserSource = () => {
    switch (formType) {
      case 'advocate':
        return 'advocate';
      case 'quote':
      default:
        return 'quote';
    }
  }

  const onSubmitCreateAccount = async (data) => {
    console.log('onSubmitCreateAccount');
    console.log(data);
    if (formStep === 1) {
    // data.email="xxx@xx.com";
    let bytes = CryptoJS.AES.decrypt(createAccountReferred.h, CIPHER_SECRET_KEY);
    let pw = bytes.toString(CryptoJS.enc.Utf8);
    // let pw='get-that-lettuce';
    console.log('pw = ' + pw);
    return firebase
          .auth()
          .signInWithEmailAndPassword(data.email, pw)
          .then((userCredential) => {
            console.log(userCredential);
            setFormStep(2);
            setLoading(false);
            return true;
          })
          .catch((error) => {
            console.log(error);
            if (_.isObject(error) && _.isString(error.code) && error.code !== '') {
              switch (error.code) {
                case 'auth/user-not-found':
                  setLoading(false);
                  // need to create user
                  setCreateAccountCreateNewUserSelection(true);
                  setFormStep(2);
                  break;
                case 'auth/invalid-email':
                  setLoading(false);
                  setFormStep(1);
                  setError('email', 'This email address is invalid, please re-enter and try again');
                  return { success: false, message: 'This email address is invalid, please re-enter and try again.' };
                case 'auth/user-disabled':
                  setLoading(false);
                  setResponse({type: 'error', message:'The account for this email address is currently disabled. Please contact support@isppowerlink.com to get your account reactivated' });
                  return {success: false};
                case 'auth/wrong-password':
                default:
                  // firebase.auth().sendPasswordResetEmail(data.email);
                  setLoading(false);
                  setFormStep(3);
                  setResponse({ type: 'success', message: 'To confirm your email address and allow you to set your password, we have sent you an email. Check your inbox and follow the password reset instructions.' });
                  return {success: true};
                  break;
              }
            } else {
              // firebase.auth().sendPasswordResetEmail(data.email);
              setLoading(false);
              setFormStep(3);
              return {success: true}
            }
          });
        } else if (formStep === 2) {
          const user = await firebase.auth().currentUser;
          const afterPasswordReset = () => {
            console.log('here');
            var userFinishedSignupCallable = firebase
              .functions()
              .httpsCallable('userFinishedSignupCallable');
            var userFinishedResponse = userFinishedSignupCallable({user_id: user.uid});
          }
          user
            .updatePassword(data.password)
            .then(() => {
              afterPasswordReset();
              setLoading(false);
              if (redirectSuccess) {
                props.history.push('/advocate-account-created');
              } else {
                setFormStep(3);
                setResponse({ code: 200, type: 'success', message: 'Account setup completed. Download the ISP Powerlink app and log in using your email and password.' });
              }
              return {success: true};
            })
            .catch((errorSettingPassword) => {
              firebase.auth().signOut().then(() => {
                setLoading(false);
                setFormStep(3);
                setResponse({ type: 'error', message: 'There was an error setting your password. Please click the Create Account button from your email and try again.' });
              }).catch((error) => {
                console.log('error signing user out');
              });
            });
        }
  }

  const onSubmitIntake = async (data) => {
    console.log('onSubmitIntake');
    console.log(formStep);
    console.log(maxStep());
    if (formStep >= maxStep()) {
      console.log(data);
      var createUserResponse = await createUser(data);
      if (createUserResponse && _.isObject(createUserResponse)) {
        if (createUserResponse.success === false || createUserResponse.success === false) {
          if (createUserResponse.error && createUserResponse.error.code) {
            switch (createUserResponse.error.code) {
              case 'auth/email-already-exists':
              case 'auth/email-already-in-use':
                setResponse({
                  message: 'That email address is already in use!',
                  type: 'error',
                });
                break;

              case 'auth/invalid-email':
                setResponse({
                  message: 'That email address is invalid.',
                  type: 'error',
                });
                break;

              case 'auth/weak-password':
                setResponse({
                  message: 'The provided password is weak.',
                  type: 'error',
                });
                break;

              default:
                setResponse({
                  message: 'Somthing went wrong, please try again.',
                  type: 'error',
                });
                break;
            }
            setFormStep(1);
          } else {
            setResponse({
              message: 'Somthing went wrong, please try again.',
              type: 'error',
            });
            setFormStep(1);
          }
        } else {
          if (redirectSuccess) {
            if (formType === 'advocate') {
              setLoading(true);
              setTimeout(() => {
                history.push('/advocate-account-created');
                setLoading(false);
              },1500);
              return;
            } else {
              setTimeout(() => {
                history.push('/advocate-account-created');
                setLoading(false);
              },1500);
              return;
            }
          } else {
            setResponse({
              message: 'We have received your request. We will be in touch shortly!',
              type: 'success',
            });
            setFormStep(1);
            reset();
          }
        }

      }
    } else {
      const newStep = formStep + 1;
      setFormStep(newStep);
      setTimeout(() => { setResponse(null) }, FORM_RESPONSE_SHOW_TIME * 1000);
    }
  };
  const stepBack = () => {
    console.log('go back');
    setFormStep(Math.max(0,formStep - 1));
  }

  const createUser = async (formData) => {
    setLoading(true);
    var createQuoteCallable = firebase
          .functions()
          .httpsCallable('createQuoteCallable');
    var data = Object.assign({}, formData, {
      is_company_lead: true,
      activated: false,
      send_create_account: false,
      source: createUserSource(),
      type: 'SA',
    });
    if (referrerId && _.isString(referrerId) && referrerId !== '') {
      data.parent_id = referrerId;
    }
    // if (placeDetails && placeDetails.lat) {
    //   data.lat = placeDetails.lat;
    // }
    // if (placeDetails && placeDetails.lng) {
    //   data.lng = placeDetails.lng;
    // }
    // if (data.state === null && placeDetails && placeDetails.state) {
    //   data.state = placeDetails.state;
    // }
    // if (data.zip === null && placeDetails && placeDetails.zip) {
    //   data.zip = placeDetails.zip;
    // }
    // if (
    //   (data.place_id === null || data.place_id.length === 0) &&
    //   placeDetails &&
    //   placeDetails.place_id
    // ) {
    //   data.place_id = placeDetails.place_id;
    // }
    //
    console.log('******** createQuoteCallable *******');
    console.log(data);

    var createQuoteResponse = await createQuoteCallable(data);
    setLoading(false);
    console.log('******** createQuoteResponse *******');
    if (createQuoteResponse && _.isObject(createQuoteResponse) && createQuoteResponse.data) {
      console.log(createQuoteResponse.data);
      return createQuoteResponse.data;
    }
    return null;
  }

  const renderResponse = (response) => {
    if (_.isObject(response) && _.isFinite(response.code)) {
      return renderResponseWithCode(response);
    }
    if (_.isObject(response) && _.isString(response.message)) {
      return (
        <div className={'form-response form-response-'+response.type}>{response.message}</div>
      )
    }
    return (
      <div className='form-response form-response-success'>We have received your information. We will be in touch with you shortly. Thank you!</div>
    );

  }

  const renderResponseWithCode = (response) => {
    if (_.isObject(response) && _.isFinite(response.code)) {
      switch (parseInt(response.code)) {
        case 200:
        default:
          return (
            <div style={{textAlign: 'center' }}>
              <div style={{marginBottom: 20}} className={'form-response form-response-'+response.type}>{response.message}</div>
              <DownloadLinks />
            </div>
          );
          break;

      }
    }
    return;
  }

  const madeSelection = (selection) => {
    console.log(selection);
    setLoading(true);
    setCreateAccountCreateNewUserSelection(false);

  }

  if (formType == 'createaccount') {
    if (loading) {
      return (
        <ScaleLoader color="#ffffff" loading={loading} height={45} width={4} radios={2} margin={2} />
      )
    }
    if (createAccountCreateNewUserSelection) {
      return (
        <CreateAccountCreateNewUserSelection madeSelection={madeSelection} defaultName={defaultName} defaultEmail={defaultEmail}/>
      )
    }
    return (
        <form autoComplete="on" onSubmit={handleSubmit(onSubmitCreateAccount)}>
          <div className={"form-slider"}>
          { formStep === 2 ? (
            <div className="form-slide slide-1">
              <h5 style={{textAlign: 'center', width: '100%'}}>Please enter a password</h5>
              <div className="form-field-password">
                <label htmlFor="name">Password:</label>
                <input name="password" ref={register} type="password" autoComplete="new-password"/>
              </div>
              <div className="form-field-confirm-password">
                <label htmlFor="name">Confirm Password:</label>
                <input name="confirm-password" ref={register({
                  validate: (value) => {
                    return value === watch('password');
                  }
                })} type="password" autoComplete="new-password" />
              </div>
            </div>
          ) : (
            <>
            {formStep === 3 ? (
              <div className="form-slide slide-1">
                <div className="form-response-wrapper">
                  { renderResponse(response) }
                </div>
              </div>
            ) : (
              <div className="form-slide slide-1">
                <div className="form-field-referred-by">
                  <label htmlFor="referredBy">Referred By:</label>
                  <input defaultValue={(createAccountReferrer || {}).name || ''} id="referredBy" name="referredBy" autoComplete="off" placeholder="(optional)" type="text" ref={register} onFocus={e => { setFormStep(1) }}/>
                </div>
                <div className="form-field-name">
                  <label htmlFor="name">Name:</label>
                  <input defaultValue={(createAccountReferred || {}).name || ''} id="name" name="name" autoComplete="name" placeholder="Your name" type="text" ref={register} onFocus={e => { setFormStep(1) }} />
                </div>
                <div className="form-field-email">
                  <label htmlFor="email">Email:</label>
                  <input defaultValue={(createAccountReferrer || {}).email || ''} id="email" name="email" autoComplete="email" type="email" placeholder="email@email.com" ref={register({
                            required: true,
                            pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                          })} onFocus={e => { setFormStep(1) }} />
                </div>
                <TermsWrapper className="form-field-accept-t-c">
                  <TermsCheckbox id="terms" name="terms" type="checkbox" ref={register({required: true})} />
                  <TermsLabel htmlFor="terms">I agree to the <TermsLink href="https://getpowerlink.com/terms" target="_blank" rel="noopener">terms and conditions</TermsLink></TermsLabel>
                </TermsWrapper>
              </div>
            )}
            </>
          )}
          { response === null && (
            <>
            {formStep === 1 && errors.email && 'Valid email is required.'}
            {formStep === 1 && errors.terms && 'Please accept the terms and conditions.'}
        {formStep === 2 && errors.zip && 'Valid zip is required.'}
          </>
      )}
    </div>
    { formStep < 3 && (
      <>
          <input type="submit" value="Create Account"/>
          { response !== null && _.isObject(response) && (
            <div className={'form-response form-response-'+response.type}>{response.message}</div>
          )}
          { false && formStep > 1 && (
            <button onclick={stepBack} style={{backgroundColor: 'transparent', color: '#ffffff', padding: 8, marginLeft: -8}}>Back</button>
          )}
          </>
        )}
        </form>
      );
  } else {
    return (
      <InviteIntakeForm className={(isIframe ? "iframe" : "")}>
        <form autoComplete="on" onSubmit={handleSubmit(onSubmitIntake)} className={(pathElectricBill && _.isString(pathElectricBill) && electricBillOptions.includes(pathElectricBill) ? '' : 'showElectricBill')}>
          <div className={"form-slider on-slide-"+(formStep - 1)}>
            <div className="form-slide slide-1">
              <div className="form-field-name">
                <label htmlFor="name">Name:</label>
                <input defaultValue={defaultName || ''} id="name" name="name" autoComplete="name" placeholder="Your name" type="text" ref={register} onFocus={e => { setFormStep(1) }} />
              </div>
              <div className="form-field-email">
                <label htmlFor="email">Email:</label>
                <input defaultValue={defaultEmail || ''} id="email" name="email" autoComplete="email" type="email" placeholder="email@email.com" ref={register({
                          required: true,
                          pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        })} onFocus={e => { setFormStep(1) }} />
              </div>
              <div className="form-field-phone">
                <label htmlFor="phone">Phone:</label>
                <input id="phone" name="phone" autoComplete="tel" type="tel" placeholder="000-000-0000" ref={register({ required: true, maxLength: 11, minLength: 8 })} onFocus={e => { setFormStep(1) }} />
              </div>
              { pathElectricBill && _.isString(pathElectricBill) && electricBillOptions.includes(pathElectricBill) ? (
                <input id="electricBill" name="electricBill" type="hidden" value={pathElectricBill} />
              ) : (
              <div className="form-field-electricBill">
                <label htmlFor="electricBill">Average Monthly Electric Bill:</label>
                <select id="electricBill" name="electricBill"  ref={register}>
                  <option key="default" value="">Choose one (best guess if unsure)</option>
                  {electricBillOptions.map(value => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
              </div>
              )}
            </div>
            <div className="form-slide slide-2">
              <h5 style={{textAlign: 'center', width: '100%'}}>So we can give you an accurate quote, please enter your address details</h5>
              <div className="form-field-street">
                <label htmlFor="street">Street:</label>
                <input id="street" name="street" autoComplete="street-address" type="text" placeholder="123 Main" ref={register} onFocus={e => { setFormStep(2) }} />
              </div>
              <div className="form-field-city">
                <label htmlFor="city">City:</label>
                <input id="city" name="city" autoComplete="address-level2" type="text" placeholder="City" ref={register} onFocus={e => { setFormStep(2) }} />
              </div>
              <div className="form-field-state">
                <label htmlFor="state">State:</label>
                <input id="state" name="state" autoComplete="address-level1" type="text" placeholder="State" ref={register} onFocus={e => { setFormStep(2) }} />
              </div>
              <div className="form-field-zip">
                <label htmlFor="zip">Zip:</label>
                <input id="zip" name="zip" autoComplete="postal-code" type="text" placeholder="Zip" ref={register({ required: true, maxLength: 5, minLength: 5 })} onFocus={e => { setFormStep(2) }} />
              </div>
            </div>
          </div>
          { response === null && (
            <>
        {formStep === 1 && errors.email && 'Valid email is required.'}
        {formStep === 2 && errors.zip && 'Valid zip is required.'}
          </>
      )}
          <input type="submit" value={ formType === 'advocate' ? "Become an Advocate" : "Get Free Quote"}/>
          { response !== null && _.isObject(response) && (
            <div className={'form-response form-response-'+response.type}>{response.message}</div>
          )}
          { false && formStep > 1 && (
            <button onclick={stepBack} style={{backgroundColor: 'transparent', color: '#ffffff', padding: 8, marginLeft: -8}}>Back</button>
          )}
        </form>
      </InviteIntakeForm>
    )
  }
}

export default EmbedForm;

const InviteIntakeForm = styled.div`
  z-index: 1;
  min-height: 400px;
  display: flex;
  align-items: flex-end;
  padding-bottom: 100px;
  margin-left: -15px;
  margin-right: -15px;
  &.iframe {
    input {
      flex-basis: 100%;
      padding: 14px;
      border-radius: 4px;
      border: 1px solid rgb(209, 209, 209);
    }
    label {
      color: rgb(31, 31, 31);
      font-weight: 400;
      display: block;
    }
  }
  & > form {
    display: flex;
    flex-flow: column;
    flex-wrap: wrap;
    overflow: hidden;
    & > .form-slider {
      max-width: 100%;
      display: flex;
      flex-flow: row;
      flex-wrap: nowrap;
      position: relative;
      transition: left .75s;
      align-items: flex-start;
      &.on-slide-0 {
        left: 0%;
      }
      &.on-slide-1 {
        left: -100%;
      }
      &.on-slide-2 {
        left: -200%;
      }
      &.on-slide-3 {
        left: -300%;
      }
      &.on-slide-4 {
        left: -400%;
      }
      &.on-slide-5 {
        left: -500%;
      }
      &.on-slide-6 {
        left: -600%;
      }
      & > .form-slide {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        width: 100%;
        flex-basis: 100%;
        min-width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        & >  div {
          display: flex;
          flex-flow: column;
          flex-basis: 100%;
          margin: 8px 0;
          max-width: 100%;
          &.form-field-phone {
            max-width: 145px;
            margin-bottom: 16px;
          }
          &.form-field-city {
            flex: 7;
          }
          &.form-field-state {
            flex: 8;
            margin-left: 8px;
            margin-right: 8px;
            min-width: 75px;
          }
          &.form-field-zip {
            flex: 5;
            min-width: 50px;
          }
        }
      }
    }
      &.showElectricBill {
        & > .form-slider {
          & > .form-slide {
            & >  div {
              max-width: 100%;
              &.form-field-name {
                flex: 1 0 50%;
                order: 1;
                margin-right: 16px;
              }
              &.form-field-email {
                order: 3;
              }
              &.form-field-phone {
                flex: 1 0 50%;
                order: 2;
                margin-bottom: 8px;
              }
              &.form-field-electricBill {
                order: 4;
              }
            }
          }
        }
      }

    select {
      flex-basis: 100%;
      padding: 16px 12px;
      border-radius: 4px;
      border: 1px solid rgb(209,209,209);
    }
    input {
      flex-basis: 100%;
      padding: 14px;
      border-radius: 4px;
      border: none;
      &[type="submit"] {
        background: rgb(251, 149, 16);
        font-weight: 600;
        color: #ffffff;
        font-size: 1.5em;
        margin-top: 8px;
        margin-left: 15px;
        margin-right: 15px;
        cursor: pointer;
      }
      &:focus {
        outline: none;
      }

    }
    .form-response {
      margin: 8px 15px 0;
      font-weight: 600;
      &.form-response-error {
        color: red;
      }
      &.form-response-success {
        color: green;
      }
    }
    label {
      color: white;
      font-weight: 400;
      display: block;
    }
    button {
      &:focus, &:active {
        outline: none;
        border: none;
      }
    }
  }
`;

const TermsLink = styled.a`
  color: #fb9510;
  text-decoration: none !important;
`;

const TermsWrapper = styled.div`
  flex-flow: row !important;
  align-items: center;
`;

const TermsCheckbox = styled.input`
  flex: 0;
  flex-basis: 0 !important;
`;
const TermsLabel = styled.label`
  margin-left: 8px;
`;

import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import EmbedForm from "../EmbedForm/EmbedForm";

function CreateAccountCreateNewUserSelection(props) {
  const [formType, setFormType] = useState(null);

  useEffect(() => {
    console.log('');
    console.log('');
    console.log('');
    console.log('props');
    console.log(props);
  }, [props.makeSelection, props.defaultName, props.defaultEmail]);

  const makeQuote = () => {
    setFormType('quote');
    // props.madeSelection('quote');
  }

  const makeAdvocate = () => {
    setFormType('advocate');
    // props.madeSelection('advocate');
  }

  const onPressWhatsAnAdvocate = () => {
    console.log('onPressWhatsAnAdvocate');
    alert('A solar "advocate" is someone who may or may not already have solar on their home, who is interested in referring others to get solar. Advocates earn money for every referral who installs solar on their house');
  }

  if (formType === null) {
    return (
      <>
      <h2>I'd like:</h2>
        <SelectionButton onClick={makeQuote}>To Get a Solar Quote</SelectionButton>
        <div style={{marginTop: 15}}></div>
        <SelectionButton className="secondary" onClick={makeAdvocate}>To Become an Advocate</SelectionButton>
        <button onClick={onPressWhatsAnAdvocate} style={{alignSelf: 'flex-end', color: 'white', backgroundColor: 'transparent', border: 'none',marginTop: 5}}>What's this?</button>
      </>
    );
  } else {
    return (
      <EmbedForm formType={formType} referrer={null} redirectSuccess={true} defaultName={props.defaultName} defaultEmail={props.defaultEmail}/>
    );
  }
}

export default CreateAccountCreateNewUserSelection;

const SelectionButton = styled.button`
  background: #fb9510;
  font-weight: 600;
  color: #ffffff;
  font-size: 1.5em;
  margin-top: 6px;
  margin-left: 15px;
  margin-right: 15px;
  padding: 14px;
  flex-basis: 100%;
  border-radius: 4px;
  border: none;
  width: 100%;
  &.secondary {
    background: #034577;
  }
`;

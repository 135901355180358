import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import firebase, { cronDatabase } from "../../../config/firebase.js";
import { roleTypesForCapitalization } from "../../../config/config.js";
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUser, faUsers, faPen, faTrashAlt, faFileExport, faFunnelDollar } from "@fortawesome/free-solid-svg-icons";
import ListGroup from 'react-bootstrap/ListGroup'
import { capitalize } from '../../../utils/strings';

import _ from "lodash";

import "./AdminNotificationsScheduled.min.css";

library.add(faUser, faUsers, faPen, faTrashAlt, faFileExport, faFunnelDollar);

const { ExportCSVButton } = CSVExport;
const { SearchBar } = Search;

const TEST_LOCAL = false;

function AdminNotificationsScheduled(props) {
  const { register, handleSubmit, errors, reset, watch, setError } = useForm(); // initialize the hook
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [paymentIDsToSend, setPaymentIDsToSend] = useState([]);
  const [notificationIDsToEdit, setNotificationIDsToEdit] = useState([]);
  const [notificationIDsToDelete, setNotificationIDsToDelete] = useState([]);
  const [scheduledNotifications, setScheduledNotifications] = useState([]);
  const [userType, setUserType] = useState(null);

  useEffect(() => {
    console.log(TEST_LOCAL);
    // if (!TEST_LOCAL) {
    //   try {
    //     var result = loadScheduledNotifications();
    //     console.log(result);
    //   } catch (e) {
    //     console.log('got an error loading data');
    //     console.error(e.message);
    //   } finally {
    //     console.log('finally done with useEffect');
    //   }
    //   // return unsubscribeToPendingPayoutsData();
    // }
    try {
      if (_.isString(props.userType) && props.userType !== '') {
        loadScheduledNotifications(props.userType);
      } else {
        loadScheduledNotifications();
      }
    } catch (e) {
      console.log('got an error loading data');
      console.error(e.message);
    } finally {
      console.log('finally done with useEffect');
    }
  }, [props]);

  // const unsubscribeToUsersData = () => {
  //   firebase.database().ref('users').off('value');
  // }



  const loadScheduledNotifications = async (userType = null) => {
    try {
      console.log('before');
      cronDatabase.child('notifications').orderByChild('sendAt').once('value').then((snapshot) => {
        var notificationData = [];
        console.log('here');
        if (snapshot.exists()) {
          if (_.isObject(snapshot.val()) && Object.keys(snapshot.val()).length > 0) {
            for (const [key, value] of Object.entries(snapshot.val())) {
              var u = _.pick(value,['createdAt','sendAt','message','title','email','u_id','topic']);
              if (_.isString(u.topic) && u.topic !== '') {
                var t = capitalize(u.topic.replace(/_/g, ' '),true);
                console.log('t = '+t);
                roleTypesForCapitalization.forEach((rt, i) => {
                  t = t.replace(new RegExp(rt.search, "g"), rt.replace);
                });
                u.sendTo = t;
                delete u.topic;
              }
              if (_.isString(u.u_id) && u.u_id !== '') {
                u.sendTo = u.u_id;
                delete u.u_id;
              }
              if (_.isObject(u) && Object.keys(u).length > 0) {
                notificationData.push(u);
              }
            }
          }

          console.log(notificationData);
          setScheduledNotifications(notificationData.sort((a, b) => a.sendAt > b.sendAt && 1 || -1));
          // setScheduledNotifications(snapshot.val());
        } else {
          console.log('no snapshot');
        }
      });
      console.log('after');
    } catch (e) {
      console.log('an error occurred');
      console.log(e.message);
    } finally {
      console.log('finally');
    }

    // try {
    //   var stripeusersCallable = firebase.functions().httpsCallable('stripeusersCallable');
    //   setScheduledNotifications(await stripeusersCallable());
    //
    // } catch (e) {
    //
    //   console.log('error');
    //     console.log(e.message);
    // } finally {
    //   console.log('finally');
    // }
    const processNotifications = (snapshot) => {
      if (snapshot.exists()) {
        var userData = [];
        if (_.isObject(snapshot.val()) && Object.keys(snapshot.val()).length > 0) {
          for (const [key, value] of Object.entries(snapshot.val())) {
            var u = _.pick(value,['name','email','city','state','id','type','referral_code','org_id']);
            if (_.isString(u.state)) {
              u.city = u.city + ', '+u.state;
              delete u.state;
            }
            if (!(_.isString(u.id) && u.id !== '')) {
              u.id = key;
            }
            console.log(`${key}: ${u}`);
            if (_.isObject(u) && Object.keys(u).length > 0) {
              userData.push(u);
            }
          }
        }

        console.log(userData);
        setScheduledNotifications(userData.sort((a, b) => a.name > b.name && 1 || -1));
        // setScheduledNotifications(snapshot.val());
      }
    };
  }


  const handleClickedDeleteNotificationForRow = (row_id) => {
    console.log('handleClickedDeleteNotificationForRow '+row_id);
    setSelectedRows([]);
    setPaymentIDsToSend([]);
    setNotificationIDsToEdit([]);
    setNotificationIDsToDelete([row_id]);
    setShowConfirmModal(true);
  }

  const handleClickedEditNotificationForRow = (row_id) => {
    setSelectedRows([]);
    setPaymentIDsToSend([]);
    setNotificationIDsToEdit([row_id]);
    setNotificationIDsToDelete([]);
    setShowConfirmModal(true);
  }

    const handleClickedSendPaymentForSelectedRows = () => {
      setPaymentIDsToSend(selectedRows);
      setNotificationIDsToEdit([]);
      setNotificationIDsToDelete([]);
      setShowConfirmModal(true);
    }

    const handleClickedEditNotificationForSelectedRows = () => {
      setPaymentIDsToSend([]);
      setNotificationIDsToEdit(selectedRows);
      setNotificationIDsToDelete([]);
      setShowConfirmModal(true);
    }

    const handleClickedDeleteNotificationsForSelectedRows = () => {
      setPaymentIDsToSend([]);
      setNotificationIDsToEdit([]);
      setNotificationIDsToDelete(selectedRows);
      setShowConfirmModal(true);
    }

    const handleCloseConfirmModal = () => {
      setPaymentIDsToSend([]);
      setShowConfirmModal(false);
    }



  const actionsFormatter = (cell, row) => (
      <>
      <OverlayTrigger
        key='edit-notification-key'
        placement='bottom'
        overlay={
          <Tooltip id={`tooltip-bottom`}>
            Edit selected notification
          </Tooltip>
        }
      >
        <ActionButton variant="secondary" onClick={() => { handleClickedEditNotificationForRow(); }}><ButtonIcon icon="pen" /></ActionButton>
      </OverlayTrigger>
      <OverlayTrigger
          key='cancel-payment-key'
          placement='bottom'
          overlay={
            <Tooltip id={`tooltip-bottom`}>
              Delete pending payment
            </Tooltip>
          }
        >
          <ActionButton variant="danger" onClick={() => { handleClickedDeleteNotificationForRow(row.id); }}><ButtonIcon icon="trash-alt" /></ActionButton>
        </OverlayTrigger>
      </>
);


  const columns= [
    {
      text: 'Send',
      dataField: 'sendAt',
      sort: true,
      type: 'date',
      formatter: (cell, row) => renderDate(cell),
    },
    {
      text: 'Send To',
      dataField: 'sendTo',
      sort: true
    },
    {
      text: 'Title',
      dataField: 'title',
      sort: true
    },
    {
      text: 'Message',
      dataField: 'message',
      sort: true
    },
    {
      dataField: 'actions',
      text: 'Actions',
      isDummyField: true,
      csvExport: false,
      formatter: actionsFormatter,
      headerStyle: () => {
        return { width: "160px" };
      }
    }
  ];

  const renderDate = (cell) => {
    var date = new Date(cell);
    return date.toLocaleString("en-us");
  }


  const handleOnSelect = (row, isSelect) => {
    console.log(row);
    console.log(isSelect);
    var currentlySelected = selectedRows;
      if (isSelect) {
        setSelectedRows([...selectedRows, row.id]);
      } else {
        setSelectedRows(selectedRows.filter(x => x !== row.id));
      }
    }

  const handleOnSelectAll = (isSelect, rows) => {
      const ids = rows.map(r => r.id);
      console.log(isSelect,ids);
      if (isSelect) {
        setSelectedRows(ids);
      } else {
        setSelectedRows([]);
      }
    }


  const unsubscribeToPendingPayoutsData = () => {
    firebase.database().ref('go').off('value');
  }
  const onSubmitCreateEC = async (data) => {
    console.log(data);
    try {
      var createECinPipedriveCallable = firebase
        .functions()
        .httpsCallable('createECinPipedriveCallable');
      var createECinPipedriveCallableResponse = await createECinPipedriveCallable({name: data.name, email: data.email});
      console.log('createECinPipedriveCallableResponse = '+JSON.stringify(createECinPipedriveCallableResponse));
    } catch (e) {
      console.log('createECinPipedriveCallable error');
      console.log(e.message);

    } finally {
      console.log('createECinPipedriveCallable finally');

    }
  }
  const MyExportCSV = (props) => {
    const handleClickedExportSelectedRows = () => {
      var rowsToExport = prepareForExportCSV((selectedRows && _.isArray(selectedRows) && selectedRows.length > 0 ? selectedRows : scheduledNotifications.map(row => row.id)));
      console.log('export rows ');
      console.log(rowsToExport);
      // props.onExport();
    };
    return (
      <div>
        <button style={{opacity: 0}} disabled={true} className="btn btn-primary" onClick={ handleClickedExportSelectedRows }><ButtonIcon icon="file-export"></ButtonIcon>Export {(selectedRows && _.isArray(selectedRows) && selectedRows.length === 1 ? '1 payout' : (selectedRows && _.isArray(selectedRows) && selectedRows.length > 1 ? selectedRows.length + ' payouts' : 'all payouts'))} as CSV</button>
      </div>
    );
  };

  const prepareForExportCSV = (data) => {
    return data;

  }


    const renderMultiSelectActionButtons = () => (
      <>
      <div style={{alignSelf: 'center', marginRight: 12}}>With selected notifications: </div>
        <OverlayTrigger
          key='cancel-payment-key'
          placement='bottom'
          overlay={
            <Tooltip id={`tooltip-bottom`}>
              Delete selected notifications
            </Tooltip>
          }
        >
          <ActionButton variant="danger" onClick={() => { handleClickedDeleteNotificationsForSelectedRows(); }} disabled={(selectedRows && _.isArray(selectedRows) && selectedRows.length > 0 ? '' : 'disabled')}><ButtonIcon icon="trash-alt" /> Delete</ActionButton>
        </OverlayTrigger>
      </>
    )


      const renderModals = () => {
        if (!showConfirmModal) {
          return null;
        }
        if (paymentIDsToSend && _.isArray(paymentIDsToSend) && paymentIDsToSend.length > 0) {
          return renderSendPaymentModal();
        }
        if (notificationIDsToEdit && _.isArray(notificationIDsToEdit) && notificationIDsToEdit.length > 0) {
          return renderEditPaymentModal();
        }
        if (notificationIDsToDelete && _.isArray(notificationIDsToDelete) && notificationIDsToDelete.length > 0) {
          return renderDeleteNotificationModal();
        }
      }


        const renderSendPaymentModal = () => {
          var payments = [];
          if (paymentIDsToSend && _.isArray(paymentIDsToSend) && paymentIDsToSend.length > 0) {
            payments = rowsForIds(paymentIDsToSend);
          }
          var payments = rowsForIds(paymentIDsToSend);

          if (!(payments && _.isArray(payments) && payments.length > 0)) {
            return (
              <Modal show={showConfirmModal} onHide={handleCloseConfirmModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>There was an error retrieving the payments</Modal.Body>
                <Modal.Footer>
                  <ActionButton variant="secondary" onClick={handleCloseConfirmModal}>
                    Close
                  </ActionButton>
                </Modal.Footer>
              </Modal>
            );
          }
        }


            const renderEditPaymentModal = () => (
              <Modal show={showConfirmModal} onHide={handleCloseConfirmModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Edit payment{notificationIDsToEdit && _.isArray(notificationIDsToEdit) && notificationIDsToEdit.length > 1 ? 's' : ''}?</Modal.Title>
                </Modal.Header>
                <Modal.Body>Would you like to edit the payment {notificationIDsToEdit}?</Modal.Body>
                <Modal.Footer>
                  <ActionButton variant="danger" onClick={handleCloseConfirmModal}>
                    Cancel
                  </ActionButton>
                  <ActionButton variant="secondary" onClick={handleConfirmEditPayment}>
                    Edit Payment
                  </ActionButton>
                </Modal.Footer>
              </Modal>
            );

            const renderDeleteNotificationModal = () => (
              <Modal show={showConfirmModal} onHide={handleCloseConfirmModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Delete notification{notificationIDsToDelete && _.isArray(notificationIDsToDelete) && notificationIDsToDelete.length > 1 ? 's' : ''}?</Modal.Title>
                </Modal.Header>
                <Modal.Body>Would you like to delete the notifications to {notificationIDsToDelete}?<br/><br/><h3>This cannot be un-done.</h3></Modal.Body>
                <Modal.Footer>
                  <ActionButton variant="secondary" onClick={handleCloseConfirmModal}>
                    Cancel
                  </ActionButton>
                  <ActionButton variant="danger" onClick={handleConfirmDeleteNotification}>
                    Delete Notification
                  </ActionButton>
                </Modal.Footer>
              </Modal>
            );
            const rowsForIds = (ids) => {
              var payouts = scheduledNotifications.filter ( row => ids.includes(row.id) );
              if (payouts && _.isArray(payouts) && payouts.length > 0) {
                console.log('rowsForIds('+JSON.stringify(ids)+')');
                console.info(payouts);
                return payouts;
              }
              return [];
            }



              const handleConfirmSendPayment = () => {
                console.log('send payments for '+JSON.stringify(paymentIDsToSend));
                setPaymentIDsToSend([]);
                handleCloseConfirmModal();
              }

              const handleConfirmEditPayment = () => {
                console.log('edit payments for '+JSON.stringify(notificationIDsToEdit));
                setNotificationIDsToEdit([]);
                handleCloseConfirmModal();
              }

              const handleConfirmDeleteNotification = () => {
                console.log('delete notifications for '+JSON.stringify(notificationIDsToDelete));
                setNotificationIDsToDelete([]);
                handleCloseConfirmModal();
              }
              const selectRow = {
                mode: 'checkbox',
                clickToSelect: false,
                selected: selectedRows,
                onSelect: handleOnSelect,
                onSelectAll: handleOnSelectAll
              };

  return (
    <ToolkitProvider
      keyField="id"
      data={ scheduledNotifications }
      columns={ columns }
      exportCSV={ {
        fileName: 'custom.csv',
        separator: '|',
        ignoreHeader: true,
        noAutoBOM: false
      } }
    >
      {
        props =>
        (
          <div>
            <SelectedItemsActionsWrapper>
              <MyExportCSV />
              <div style={{flex:1, flexBasis: '100%', maxWidth: 500, width: '100%'}}>
              <SearchBar style={{width: '100%'}} placeholder="Search for a user or notification"/>
              </div>
                <div>
              {renderMultiSelectActionButtons()}
              </div>
            </SelectedItemsActionsWrapper>
            <hr />
            <BootstrapTable keyField='id' data={scheduledNotifications} columns={columns} selectRow={selectRow} />
              {
                renderModals()
              }
          </div>
        )
      }
    </ToolkitProvider>
  )
}

const SelectedItemsActionsWrapper = styled.div`
display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 40px 40px 8px;
`;

const ActionButton = styled(Button)`
  margin: 0px 4px;
`;

const ButtonIcon = styled(FontAwesomeIcon)`
margin: 0px 4px;
`;

export default AdminNotificationsScheduled;

import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import firebase from "../../config/firebase.js";
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUser, faUsers, faPen, faTrashAlt, faFileExport, faFunnelDollar, faBell, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import ListGroup from 'react-bootstrap/ListGroup';
import { SegmentedControl } from 'segmented-control-react';
import AdminNotificationsScheduled from './AdminNotificationsScheduled/AdminNotificationsScheduled';
import AdminNotificationsByUser from './AdminNotificationsByUser/AdminNotificationsByUser';
import AdminNotificationsByUserDetail from './AdminNotificationsByUser/AdminNotificationsByUserDetail/AdminNotificationsByUserDetail';
import AdminNotificationsCreateNew from './AdminNotificationsCreateNew/AdminNotificationsCreateNew';

import _ from "lodash";

import "./AdminNotificationManagement.min.css";

library.add(faUser, faUsers, faPen, faTrashAlt, faFileExport, faFunnelDollar, faBell, faChevronLeft);

const { ExportCSVButton } = CSVExport;
const { SearchBar } = Search;

const TEST_LOCAL = false;

const NOTIFICATION_SEGMENTS = ['Scheduled Notifications','Sent Notifications'];

function AdminNotificationManagement(props) {
  const { register, handleSubmit, errors, reset, watch, setError } = useForm(); // initialize the hook
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [paymentIDsToSend, setPaymentIDsToSend] = useState([]);
  const [paymentIDsToEdit, setPaymentIDsToEdit] = useState([]);
  const [paymentIDsToDelete, setPaymentIDsToDelete] = useState([]);
  const [users, setUsers] = useState([]);
  const [userType, setUserType] = useState(null);
  const [notificationSegmentIndex, setNotificationSegmentIndex] = useState(0);
  const [viewingUserID, setViewingUserID] = useState(null);

  useEffect(() => {
    try {
    } catch (e) {
      console.log('got an error loading data');
      console.error(e.message);
    } finally {
      console.log('finally done with useEffect');
    }
  }, [props]);

  const handleChange = (index) => {
    console.log('handleChange');
    setNotificationSegmentIndex(index);
  }



  const renderNotificationComponent = () => {
    switch (notificationSegmentIndex) {
      case -1:
        return <AdminNotificationsCreateNew />
      case 1:
        if (_.isString(viewingUserID) && viewingUserID !== '') {
          console.log('showing user detail');
          return <AdminNotificationsByUserDetail userID={viewingUserID} backAction={ () => { setViewingUserID(null) }} />
        }
        return <AdminNotificationsByUser selectUserID={(userID) => { console.log('setViewingUserID('+userID+')'); setViewingUserID(userID); }} />
      case 0:
      default:
        return <AdminNotificationsScheduled />
    }
    return null;
  }

  return (
    <div>
      <PageWrapper>
        <RowWrapper>
          <ActionButton style={{opacity: ((_.isString(viewingUserID) && viewingUserID !== '') ? 1 : 0)}} disabled={(!(_.isString(viewingUserID) && viewingUserID !== ''))} variant="link" onClick={() => { setViewingUserID(null); }}><ButtonIcon icon="chevron-left" /> Back</ActionButton>
          <SegmentedControl
            style={{width: 300}}
              segments={NOTIFICATION_SEGMENTS.map(n => { return {name: n} })}
              selected={notificationSegmentIndex}
              variant="base"
              onChangeSegment={handleChange}
          />
        <ActionButton style={{opacity: ((_.isString(viewingUserID) && viewingUserID !== '') ? 0 : 1)}} disabled={(_.isString(viewingUserID) && viewingUserID !== '')} variant="success" onClick={() => {
            let selected = document.getElementsByClassName('base selected');
            if (_.isArray(selected) && selected.length > 0) {
              selected[0].classList.remove('selected');
            }
            setNotificationSegmentIndex(-1); }}><ButtonIcon icon="bell" /> New</ActionButton>
        </RowWrapper>
      { renderNotificationComponent() }
      </PageWrapper>
    </div>
  )
}


const ActionButton = styled(Button)`
  margin: 6px 0px;
  padding: 0px 10px;
  height: 42px;
`;

const ButtonIcon = styled(FontAwesomeIcon)`
margin: 0px 4px;
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 40px 40px 8px;
  width: 100%;

  .r-segmented-control {
    width: auto !important;
    & > ul > li {
      padding: 20px;
    }
    border: none;
  }
  & > div {
    flex: 1;
  }
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;
export default AdminNotificationManagement;

import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import firebase from "../../config/firebase.js";
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faFileInvoiceDollar, faPen, faTrashAlt, faFileExport } from "@fortawesome/free-solid-svg-icons";
import ListGroup from 'react-bootstrap/ListGroup'

import _ from "lodash";

import "./AdminUserCreateEC1.min.css";

library.add(faFileInvoiceDollar, faPen, faTrashAlt, faFileExport);

const { ExportCSVButton } = CSVExport;
const { SearchBar } = Search;

const TEST_LOCAL = false;

function AdminUserCreateEC1(props) {
  const { register, handleSubmit, errors, reset, watch, setError } = useForm(); // initialize the hook
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [paymentIDsToSend, setPaymentIDsToSend] = useState([]);
  const [paymentIDsToEdit, setPaymentIDsToEdit] = useState([]);
  const [paymentIDsToDelete, setPaymentIDsToDelete] = useState([]);

  useEffect(() => {
    console.log(TEST_LOCAL);
    if (!TEST_LOCAL) {
      try {
        var result = loadPendingPayoutsData();
        console.log(result);
      } catch (e) {
        console.log('got an error loading data');
        console.error(e.message);
      } finally {
        console.log('finally done with useEffect');
      }
      return unsubscribeToPendingPayoutsData();
    }
  }, []);

  const loadPendingPayoutsData = async () => {
    console.log('loadPendingPayoutsData');
    try {
      console.log('before');
      // firebase.database().ref('stripe_pending_payouts').on('value', (snapshot) => {
      // });
      console.log('after');
    } catch (e) {
      console.log('an error occurred');
      console.log(e.message);
    } finally {
      console.log('finally');
    }

    // try {
    //   var stripePayoutsPendingCallable = firebase.functions().httpsCallable('stripePayoutsPendingCallable');
    //   setPayoutsPending(await stripePayoutsPendingCallable());
    //
    // } catch (e) {
    //
    //   console.log('error');
    //     console.log(e.message);
    // } finally {
    //   console.log('finally');
    // }

  }

  const unsubscribeToPendingPayoutsData = () => {
    firebase.database().ref('go').off('value');
  }
  const onSubmitCreateEC = async (data) => {
    console.log(data);
    try {
      var createECinPipedriveCallable = firebase
        .functions()
        .httpsCallable('createECinPipedriveCallable');
      var createECinPipedriveCallableResponse = await createECinPipedriveCallable({name: data.name, email: data.email});
      console.log('createECinPipedriveCallableResponse = '+JSON.stringify(createECinPipedriveCallableResponse));
    } catch (e) {
      console.log('createECinPipedriveCallable error');
      console.log(e.message);

    } finally {
      console.log('createECinPipedriveCallable finally');

    }
  }

  return (
    <form autoComplete="on" onSubmit={handleSubmit(onSubmitCreateEC)}>
      <h5 style={{textAlign: 'center', width: '100%'}}>Create an EC1</h5>

      <div className="form-field-name">
        <label htmlFor="name">Name:</label>
        <input id="name" name="name" autoComplete="name" placeholder="Full name of EC1" type="text" ref={register} />
      </div>
      <div className="form-field-email">
        <label htmlFor="email">Email:</label>
        <input id="email" name="email" autoComplete="email" type="email" placeholder="email@ionsolarpros.com" ref={register({
                  required: true,
                  pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                })}  />
      </div>
      <div className="form-field-password">
        <label htmlFor="name">Password (optional):</label>
        <input name="password" ref={register} type="password" autoComplete="new-password"/>
      </div>
      <div className="form-field-confirm-password">
        <label htmlFor="name">Confirm Password:</label>
        <input name="confirm-password" ref={register({
          validate: (value) => {
            return value === watch('password');
          }
        })} type="password" autoComplete="new-password" />
      </div>
      <input type="submit" value="Create EC"/>
    </form>
  );

/*  return (
    <>
      <SelectedItemsActionsWrapper>
        <MyExportCSV { ...props.csvProps }>Export CSV!!</MyExportCSV>
        {renderMultiSelectActionButtons()}
      </SelectedItemsActionsWrapper>
      <BootstrapTable keyField='id' data={payoutsPending} columns={columns} selectRow={ selectRow } exportCSV />
      {
        renderModals()
      }
    </>
  )*/
/*
  return (
    <ToolkitProvider
      keyField="id"
      data={ payoutsPending }
      columns={ columns }
      exportCSV={ {
        fileName: 'custom.csv',
        separator: '|',
        ignoreHeader: true,
        noAutoBOM: false
      } }
    >
      {
        props =>
        (
          <div>
            <SelectedItemsActionsWrapper>
              <MyExportCSV />
              <div style={{flex:1, flexBasis: '100%', maxWidth: 500, width: '100%'}}>
              <SearchBar style={{width: '100%'}} placeholder="Search for a user or lead"/>
              </div>
                <div>
              {renderMultiSelectActionButtons()}
              </div>
            </SelectedItemsActionsWrapper>
            <hr />
            <BootstrapTable keyField='id' data={payoutsPending} columns={columns} selectRow={ selectRow } />
              {
                renderModals()
              }
          </div>
        )
      }
    </ToolkitProvider>
  )*/
}

const SelectedItemsActionsWrapper = styled.div`
display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 40px 40px 8px;
`;

const ActionButton = styled(Button)`
  margin: 0px 4px;
`;

const ButtonIcon = styled(FontAwesomeIcon)`
margin: 0px 4px;
`;

export default AdminUserCreateEC1;

import React from "react";
import styled from 'styled-components';

function DownloadLinks() {

  return (
    <DownloadLinksWrapper>
      <DownloadLinksLink href='https://apps.apple.com/us/app/isp-powerlink/id1546150307?itsct=apps_box&itscg=30200' target="_blank">
        <DownloadLinksImage src='/assets/app-store.png'  alt="App Store"/>
      </DownloadLinksLink>
      <DownloadLinksLink href="http://play.google.com/store/apps/details?id=com.getpowerlink.powerlink&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1" target="_blank">
        <DownloadLinksImage src="/assets/google-play.png"  alt="Google Play"/>
      </DownloadLinksLink>
    </DownloadLinksWrapper>
  );
}

export default DownloadLinks;

const DownloadLinksWrapper = styled.div`

`;

const DownloadLinksLink = styled.a`
  display: inline-block;
  margin-right: 20px;
  z-index: 10;
`;

const DownloadLinksImage = styled.img`
  width: 170px;
`;

import React from "react";
import "./Footer.css";
import { isAdmin } from "../../utils/userSession";

function Footer({ history, ...props }) {

  return (
    <footer>
      <div className="wrapper">
        <span>© 2021 PowerLink. All rights reserved.</span>
        <ul>
          <li><a href="https://getpowerlink.com/terms/" target="_blank" rel="noreferrer">Terms</a></li>
          <li><a href="mailto:support@getpowerlink.com">Contact</a></li>
          {
            isAdmin() ? (
              <li><a href="/logout">Logout</a></li>
            ) : (
              <li><a href="/login">Login</a></li>
            )
          }
        </ul>
      </div>
    </footer>
  );
}


export default Footer;

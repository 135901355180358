import React, { useState, useEffect } from "react";
import { NavLink, Link, useHistory } from "react-router-dom";
import firebase from "../../../config/firebase.js";
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { CIPHER_SECRET_KEY, electricBillOptions } from "../../../config/config.js";
import CryptoJS from 'crypto-js';
import ScaleLoader from "react-spinners/ScaleLoader";
import "./AdminNotificationsCreateNew.min.css";
import DownloadLinks from "../../DownloadLinks/DownloadLinks";
import CreateAccountCreateNewUserSelection from "../../CreateAccountCreateNewUserSelection/CreateAccountCreateNewUserSelection";

import _ from "lodash";


function AdminNotificationsCreateNew(props) {
  const { register, handleSubmit, errors, reset, watch, setError } = useForm(); // initialize the hook
  const [formStep, setFormStep] = useState(1);
  const [formType, setFormType] = useState('quote');
  const [isIframe, setIsIframe] = useState(false);
  const [defaultName, setDefaultName] = useState(null);
  const [defaultEmail, setDefaultEmail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [referrerId, setReferrerId] = useState(null);
  const [createAccountReferrer, setCreateAccountReferrer] = useState(null);
  const [createAccountReferred, setCreateAccountReferred] = useState(null);
  const [pathElectricBill, setPathElectricBill] = useState(null);
  const [createAccountCreateNewUserSelection, setCreateAccountCreateNewUserSelection] = useState(false);
  const [response, setResponse] = useState(null);
  const [redirectSuccess, setRedirectSuccess] = useState(false);
  const availableFormTypes = ['quote', 'advocate', 'createaccount'];

  const history = useHistory();

  const FORM_RESPONSE_SHOW_TIME = 8; // how long the form success message shows for

  useEffect(() => {

  }, [props.formType, props.isIframe, props.referrerId, props.createAccountReferred, props.createAccountReferrer, props.redirectSuccess, props.defaultName, props.defaultEmail, props.pathElectricBill]);


  const onSubmitIntake = async (data) => {
    console.log('onSubmitIntake');
      console.log(data);
      setLoading(true);
      var createNotificationCallable = firebase
            .functions()
            .httpsCallable('createNotificationCallable');
      var data = Object.assign({}, {
        user_id: data.recipients,
        title: data.title,
        message: data.message
      });
      console.log('******** createNotificationCallable *******');
      console.log(data);
      var createNotificationResponse = await createNotificationCallable(data);
      setLoading(false);
      console.log('******** createNotificationResponse *******');
      if (createNotificationResponse && _.isObject(createNotificationResponse) && createNotificationResponse.data) {
        console.log(createNotificationResponse.data);
        reset();
        return createNotificationResponse.data;
      }
      return null;
  };


    if (loading) {
      return (
        <ScaleLoader color="#ffffff" loading={loading} height={45} width={4} radios={2} margin={2} />
      )
    }
    return (
      <InviteIntakeForm >
        <form autoComplete="on" onSubmit={handleSubmit(onSubmitIntake)} >
        <div className="form-top">
          <div className="form-text-inputs">
              <div className="form-field-title">
                <label htmlFor="title">Title:</label>
                <input defaultValue={defaultName || ''} id="title" name="title" autoComplete="off" placeholder="Notification title (optional)" type="text" ref={register} />
              </div>
              <div className="form-field-message">
                <label htmlFor="message">Message:</label>
                <input id="message" name="message" autoComplete="off" type="text" placeholder="Notification text" ref={register({
                          required: true,
                        })} />
              </div>
          </div>
          <div className="form-recipients">
            <label htmlFor="recipients">Recipients:</label>
            <select id="recipients" name="recipients"  ref={register} onChange={(value) => {console.log(value)}}>
              <option key="n6WGWUXIC2XnARgqYljTSNXcVDu2" value="n6WGWUXIC2XnARgqYljTSNXcVDu2">Chris Hayes</option>
              <option key="PuD6KwbnJXO74QuADWMrHAYXgzt2" value="PuD6KwbnJXO74QuADWMrHAYXgzt2">Andrew Feilke</option>
            </select>
          </div>
          </div>
              <div className="form-field-schedule">
                <label htmlFor="schedule">Schedule to send:</label>
                <select id="schedule" name="schedule"  ref={register} onChange={(value) => {console.log(value)}}>
                  <option key="now" value="now">Immediately</option>
                  <option key="future" value="future">Future Date/Time</option>
                </select>
              </div>
          <input type="submit" value="Send Notification(s)"/>
          { response !== null && _.isObject(response) && (
            <div className={'form-response form-response-'+response.type}>{response.message}</div>
          )}
        </form>
      </InviteIntakeForm>
    )

}

export default AdminNotificationsCreateNew;

const InviteIntakeForm = styled.div`
  z-index: 1;
  min-height: 400px;
  display: flex;
  align-items: flex-end;
  padding-bottom: 100px;
  margin-left: -15px;
  margin-right: -15px;
  max-width: 800px;
  width: 100%;
  &.iframe {
  }
  & > form {
    display: flex;
    flex-flow: column;
    flex-wrap: wrap;
    overflow: hidden;
    width: 100%;
    background-color: #efefef;
    border-radius: 10px;
    padding: 20px;
    & > .form-top {
      display: flex;
      flex-flow: row;
      flex-wrap: nowrap;
      max-width: 800px;
      width: 100%;
      flex: 1;
      & > .form-text-inputs {
        display: flex;
        flex-flow: column;
        flex: 3;
        padding-right: 20px;
        & >  div {
          display: flex;
          flex-flow: column;
          flex-basis: 100%;
          margin: 8px 0;
          max-width: 100%;
          color: black;
          &.form-field-phone {
            max-width: 145px;
            margin-bottom: 16px;
          }
          &.form-field-city {
            flex: 7;
          }
          &.form-field-state {
            flex: 8;
            margin-left: 8px;
            margin-right: 8px;
            min-width: 75px;
          }
          &.form-field-zip {
            flex: 5;
            min-width: 50px;
          }
        }
      }
      & > .form-recipients {
        flex: 2;
      }
    }
    & > .form-slider {
      max-width: 100%;
      display: flex;
      flex-flow: row;
      flex-wrap: nowrap;
      position: relative;
      transition: left .75s;
      align-items: flex-start;

      & > .form-slide {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        width: 100%;
        flex-basis: 100%;
        min-width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        & >  div {
          display: flex;
          flex-flow: column;
          flex-basis: 100%;
          margin: 8px 0;
          max-width: 100%;
          &.form-field-phone {
            max-width: 145px;
            margin-bottom: 16px;
          }
          &.form-field-city {
            flex: 7;
          }
          &.form-field-state {
            flex: 8;
            margin-left: 8px;
            margin-right: 8px;
            min-width: 75px;
          }
          &.form-field-zip {
            flex: 5;
            min-width: 50px;
          }
        }
      }
    }
      &.showElectricBill {
        & > .form-slider {
          & > .form-slide {
            & >  div {
              max-width: 100%;
              &.form-field-name {
                flex: 1 0 50%;
                order: 1;
                margin-right: 16px;
              }
              &.form-field-email {
                order: 3;
              }
              &.form-field-phone {
                flex: 1 0 50%;
                order: 2;
                margin-bottom: 8px;
              }
              &.form-field-schedule {
                order: 4;
              }
            }
          }
        }
      }

    select {
      flex-basis: 100%;
      padding: 16px 12px;
      border-radius: 4px;
      border: 1px solid rgb(209,209,209);
    }
    input {
      flex-basis: 100%;
      padding: 14px;
      border-radius: 4px;
      border: none;
      &[type="submit"] {
        background: rgb(251, 149, 16);
        font-weight: 600;
        color: #ffffff;
        font-size: 1.5em;
        margin-top: 8px;
        margin-left: 15px;
        margin-right: 15px;
        cursor: pointer;
      }
      &:focus {
        outline: none;
      }

    }
    .form-response {
      margin: 8px 15px 0;
      font-weight: 600;
      &.form-response-error {
        color: red;
      }
      &.form-response-success {
        color: green;
      }
    }
    label {
      color: black;
      font-weight: 400;
      display: block;
    }
    button {
      &:focus, &:active {
        outline: none;
        border: none;
      }
    }
  }
`;

const TermsLink = styled.a`
  color: #fb9510;
  text-decoration: none !important;
`;

const TermsWrapper = styled.div`
  flex-flow: row !important;
  align-items: center;
`;

const TermsCheckbox = styled.input`
  flex: 0;
  flex-basis: 0 !important;
`;
const TermsLabel = styled.label`
  margin-left: 8px;
`;

import firebase from '../config/firebase';
import _ from "lodash";

const admins = ['PuD6KwbnJXO74QuADWMrHAYXgzt2','n6WGWUXIC2XnARgqYljTSNXcVDu2','9mTI26wPehgl5ItuJrXhXNrQu8c2'];

export const isLogin = () => {
  const user = firebase.auth().currentUser;
  return !!user;
};

export const isAdmin = () => {
  // console.log('ALERT!!!!!!!!!!!');
  // console.log('ALERT!!!!!!!!!!!');
  // console.log('ALERT!!!!!!!!!!!');
  // console.log('ALERT!!!!!!!!!!!');
  // console.log('');
  // console.log('');
  // console.log('This is for dev purposes only, not requiring admin access');
  // console.log('');
  // console.log('');
  // console.log('ALERT!!!!!!!!!!!');
  // console.log('ALERT!!!!!!!!!!!');
  // console.log('ALERT!!!!!!!!!!!');
  // console.log('ALERT!!!!!!!!!!!');
  // return true;
  const user = firebase.auth().currentUser;
  return !!user && admins.includes(user.uid);
}


export const asyncisLogin = async (callback,options) => {
  var isL = false;
  await firebase.auth().onAuthStateChanged(function(user) {
    if (user) {
      if (_.isObject(options) && (options.needsAdmin === true)) {
        isL = admins.includes(user.uid);
      } else {
        isL = true;
      }
    } else {
      isL = false;
    }
    if (_.isFunction(callback)) {
      callback(isL);
    }
  });
  return isL;
}

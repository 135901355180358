import React from "react";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Invite from "./components/Invite/Invite";
import EmbedFormIframe from "./components/EmbedFormIframe/EmbedFormIframe";
import Login from "./components/Login/Login";
import AdminStripePayoutsPending from "./components/AdminStripePayoutsPending/AdminStripePayoutsPending";
import AdminUserCreateEC1 from "./components/AdminUserCreateEC1/AdminUserCreateEC1";
import AdminEC1Rotation from "./components/AdminEC1Rotation/AdminEC1Rotation";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import AdminUserManagement from "./components/AdminUserManagement/AdminUserManagement";
import AdminLeadManagement from "./components/AdminLeadManagement/AdminLeadManagement";
import AdminNotificationManagement from "./components/AdminNotificationManagement/AdminNotificationManagement";
import QRCodeForUser from "./components/QRCodeForUser/QRCodeForUser";
import firebase from "./config/firebase.js";
import { isAdmin } from "./utils/userSession";

import './App.css';
//
function App(props) {
  return (
    <React.StrictMode>
        { props.location.pathname !== '/embed-quote-form' && (
        <Header />
        )}
        <Switch>
          <Route path='/login' component={Login} />
          <Route path='/logout' render={() => (
            firebase.auth().signOut().then(() => {
              <Login/>
            }).catch((error) => {
              window.alert(error.message);
            })
          )} />
          <PrivateRoute path='/pending-payments' component={AdminStripePayoutsPending} />
          <PrivateRoute path='/users/:userID/QRCode/:code' component={QRCodeForUser} />
          <PrivateRoute path='/users/:userID/QRCode' component={QRCodeForUser} />
          <PrivateRoute path='/users/ec1s/rotation' component={AdminEC1Rotation} />
          <PrivateRoute path='/users/ec1s/add' component={AdminUserCreateEC1} />
          <PrivateRoute path='/users/ec1s' component={AdminUserManagement} userType="EC1"/>
          <PrivateRoute path='/users' component={AdminUserManagement} />
          <PrivateRoute path='/leads/company' component={AdminLeadManagement} leadSource="company" />
          <PrivateRoute path='/leads/:userID/:leadID' component={AdminLeadManagement} exact />
          <PrivateRoute path='/leads/:userID' component={AdminLeadManagement} exact />
          <PrivateRoute path='/leads' component={AdminLeadManagement} />
          <PrivateRoute path='/notifications' component={AdminNotificationManagement} />
        </Switch>
        { props.location.pathname !== '/embed-quote-form' && (
          <Footer />
        )}
    </React.StrictMode>
  );
}

export default withRouter(App);

import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import firebase from "../../config/firebase.js";
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUser, faUsers, faPen, faTrashAlt, faFileExport, faFunnelDollar, faUserEdit, faFilter, faDatabase } from "@fortawesome/free-solid-svg-icons";
import ListGroup from 'react-bootstrap/ListGroup'

import _ from "lodash";

import "./AdminLeadManagement.min.css";

library.add(faUser, faUsers, faPen, faTrashAlt, faFileExport, faFunnelDollar, faUserEdit,faFilter, faDatabase);

const { ExportCSVButton } = CSVExport;
const { SearchBar } = Search;

const TEST_LOCAL = false;

function AdminLeadManagement(props) {
  const { register, handleSubmit, errors, reset, watch, setError } = useForm(); // initialize the hook
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [leadIDsToEditEC1, setLeadIDsToEditEC1] = useState([]);
  const [leadIDsToEdit, setLeadIDsToEdit] = useState([]);
  const [leadIDsToDelete, setLeadIDsToDelete] = useState([]);
  const [leads, setLeads] = useState([]);
  const [viewingUserID, setViewingUserID] = useState(null);
  const [allEC1s, setAllEC1s] = useState([]);
  const [loadingAllEC1s, setLoadingAllEC1s] = useState(true);
  const [editEC1SelectedEC1ID, setEditEC1SelectedEC1ID] = useState(null);
  const { userID, leadID } = (props.match || {params: {}}).params;

  useEffect(() => {
    initialLoadOfLeads();
    getAllEC1s();
  }, []);

  // const unsubscribeToUsersData = () => {
  //   firebase.database().ref('users').off('value');
  // }

  const initialLoadOfLeads = async () => {
    try {
      if (_.isString(userID) && userID !== '') {
        setViewingUserID(userID);
        loadSingleUserID(userID);
      } else if (_.isString(props.leadSource) && props.leadSource !== '') {
        switch (props.leadSource) {
          case 'company':
            loadLeadsData({field: 'is_company_lead', value: true});
            break;
          default:
            loadLeadsData();
            break;
        }
      } else  {
        loadLeadsData();
      }
    } catch (e) {
      console.log('got an error loading data');
      console.error(e.message);
    } finally {
      console.log('finally done with useEffect');
    }
  }

  const loadSingleUserID = async (userID = null) => {
    console.log('loadSingleUserID '+userID);
    if (!(_.isString(userID) && userID !== '')) {
      return await loadLeadsData();
    }
    var getLeadsCallable = firebase
    .functions()
    .httpsCallable('leadsForUserAtNodeCallable');
  return getLeadsCallable({ user_id: userID })
    .then((getLeadsResponse) => {
      console.log(getLeadsResponse);
      setLeads(processLeadData(getLeadsResponse.data));
    })
    .catch((getLeadsCallableError) => {
      console.log('getLeadsCallableError = '+getLeadsCallableError.message);
    });
  }

  const loadLeadsData = async (filters = null) => {
    try {
      var firebaseRef = firebase.database().ref('leadsVisibility');
      if (_.isObject(filters) && _.isString(filters.field) && filters.field !== '' && filters.value !== null) {
        firebaseRef = firebaseRef.orderByChild(filters.field).equalTo(filters.value);
      }
      firebaseRef.once('value', (snapshot) => {
        if (snapshot.exists()) {
          setLeads(processLeadData(snapshot.val()));
        } else {
          setLeads([]);
        }
      });
      console.log('after');
    } catch (e) {
      console.log('an error occurred');
      console.log(e.message);
    } finally {
      console.log('finally');
    }
  }

  const processLeadData = (data) => {
    var userData = [];
    if (_.isObject(data) && Object.keys(data).length > 0) {
      for (const [key, value] of Object.entries(data)) {
        var u = value;//_.pick(value,['name','city','createdAt','kW','stage','state']);
        if (_.isString(u.city) && u.city !== '') {
          u.city = (u.city.charAt(0).toUpperCase() + u.city.slice(1)).trim();
        }
        if (_.isString(u.state)) {
          u.city = u.city + ', '+u.state;
          delete u.state;
        }
        if (_.isObject(u.SA_data) && _.isString(u.SA_data.name)) {
          u.SA_name = u.SA_data.name;
        }
        if (_.isObject(u.EC1_data) && _.isString(u.EC1_data.u_id)) {
          u.ec1_id = u.EC1_data.u_id;
        }
        if (_.isObject(u.EC1_data) && _.isString(u.EC1_data.name)) {
          u.ec1_name = u.EC1_data.name;
        }
        if (!(_.isString(u.id) && u.id !== '')) {
          if (key.includes('--')) {
            u.id = key.split('--')[1];
          }
        }
        if (!(_.isString(u.parent_id) && u.parent_id !== '')) {
          if (key.includes('--')) {
            u.parent_id = key.split('--')[0];
          }
        }
        if (_.isObject(u) && Object.keys(u).length > 0) {
          userData.push(u);
        }
      }
    }
    return userData.sort((a, b) => a.updatedAt < b.updatedAt && 1 || -1);
    return userData.sort((a, b) => {
      if (!(_.isString(a.name) && a.name !== '')) {
        return 1;
      }
      if (!(_.isString(b.name) && b.name !== '')) {
        return -1;
      }
      return (a.name || '').toLowerCase() > (b.name || '').toLowerCase() && 1 || -1;
    });
  }

  const getAllEC1s = async () => {

    try {
      var getEC1sCallable = firebase
      .functions()
      .httpsCallable('usersByUserTypeCallable');
      return getEC1sCallable('EC1')
      .then((getEC1sResponse) => {
        var ec1Array = [];
        if (_.isObject(getEC1sResponse) && _.isObject(getEC1sResponse.data)) {
          for (const [key, value] of Object.entries(getEC1sResponse.data)) {
            if (key && _.isString(key) && key !== '' && _.isObject(value) && _.isString(value.name) && value.name !== '') {
              var ec1_data = _.pick(value,['name']);
              ec1_data.id = key;
              ec1Array.push(ec1_data);
            }
          }
        }
        setAllEC1s(ec1Array.sort((a, b) => a.name > b.name && 1 || -1));
      })
      .catch((getEC1sCallableError) => {
        console.log('getEC1sCallableError = '+getEC1sCallableError.message);
      });
    } catch (e) {

    } finally {
      setLoadingAllEC1s(false);
    }
  }

    const handleClickedEditLeadEC1ForRow = (lead) => {
      console.log('here');
      console.log(lead);
      setSelectedRows([]);
      setLeadIDsToEditEC1([lead]);
      setLeadIDsToEdit([]);
      setLeadIDsToDelete([]);

      setShowConfirmModal(true);
    }

    const handleClickedEditLeadForRow = (lead) => {
      setSelectedRows([]);
      setLeadIDsToEditEC1([]);
      setLeadIDsToEdit([lead]);
      setLeadIDsToDelete([]);
      setShowConfirmModal(true);
    }

    const handleClickedDeleteLeadForRow = (lead) => {
      setSelectedRows([]);
      setLeadIDsToEditEC1([]);
      setLeadIDsToEdit([]);
      setLeadIDsToDelete([lead]);
      setShowConfirmModal(true);
    }

    const handleClickedSendLeadForSelectedRows = () => {
      setLeadIDsToEditEC1(selectedRows);
      setLeadIDsToEdit([]);
      setLeadIDsToDelete([]);
      setShowConfirmModal(true);
    }

    const handleClickedEditLeadForSelectedRows = () => {
      setLeadIDsToEditEC1([]);
      setLeadIDsToEdit(selectedRows);
      setLeadIDsToDelete([]);
      setShowConfirmModal(true);
    }

    const handleClickedDeleteLeadForSelectedRows = () => {
      setLeadIDsToEditEC1([]);
      setLeadIDsToEdit([]);
      setLeadIDsToDelete(selectedRows);
      setShowConfirmModal(true);
    }

    const handleCloseConfirmModal = () => {
      setLeadIDsToEditEC1([]);
      setShowConfirmModal(false);
    }



  const actionsFormatter = (cell, row) => {
    console.log('cell');
    console.log(cell);
    console.log('row');
    console.log(row);
    return (
      <>
      <OverlayTrigger
        key={`view-lead-in-pipedrive-${row.id}`}
        placement='bottom'
        overlay={
          <Tooltip id={`tooltip-bottom`}>
            View in Pipedrive
          </Tooltip>
        }
      >
      <a href={`https://console.firebase.google.com/u/0/project/isp-2-0-app/database/isp-2-0-app/data/leads/${row.parent_id}/${row.id}`} target="_blank">
        <ActionButton variant="primary"><ButtonIcon icon="filter" /></ActionButton></a>
      </OverlayTrigger>
      <OverlayTrigger
        key={`view-lead-in-db-${row.id}`}
        placement='bottom'
        overlay={
          <Tooltip id={`tooltip-bottom`}>
            View in database
          </Tooltip>
        }
      >
      <a href={`https://console.firebase.google.com/u/0/project/isp-2-0-app/database/isp-2-0-app/data/leads/${row.parent_id}/${row.id}`} target="_blank">
        <ActionButton variant="primary"><ButtonIcon icon="database" /></ActionButton></a>
      </OverlayTrigger>

        <OverlayTrigger
          key={`edit-ec1-in-db-${row.id}`}
          placement='bottom'
          overlay={
            <Tooltip id={`tooltip-bottom`}>
              Edit EC1
            </Tooltip>
          }
        >
          <ActionButton variant="secondary" onClick={() => { handleClickedEditLeadEC1ForRow(row); }}><ButtonIcon icon="user-edit" /></ActionButton>
        </OverlayTrigger>

        <OverlayTrigger
          key='edit-lead-key'
          placement='bottom'
          overlay={
            <Tooltip id={`tooltip-bottom`}>
              Edit Lead Details
            </Tooltip>
          }
        >
          <ActionButton variant="secondary" onClick={() => { handleClickedEditLeadForRow(row); }}><ButtonIcon icon="pen" /></ActionButton>
        </OverlayTrigger>
        <OverlayTrigger
          key='cancel-lead-key'
          placement='bottom'
          overlay={
            <Tooltip id={`tooltip-bottom`}>
              Delete lead
            </Tooltip>
          }
        >
          <ActionButton variant="danger" onClick={() => { handleClickedDeleteLeadForRow(row); }}><ButtonIcon icon="trash-alt" /></ActionButton>
        </OverlayTrigger>
      </>
)
}

const renderHierarchyDataCell = (row) => (
    <>
    <ul style={{listStyle: 'none'}}>
      {
        ['ECO3','ECO2','ECO1','EC1','EC2','BPP','SA'].map((EC_type, i) => {
          if (_.isObject(row[`${EC_type}_data`]) && _.isString(row[`${EC_type}_data`].name) && row[`${EC_type}_data`].name !== ''){
            return (
            <Link to={`/users/${row[`${EC_type}_data`].u_id}`} style={{textDecoration: 'none'}}><li style={{color: '#212529', textDecoration: 'none'}}><span style={{fontWeight: '900'}}>{EC_type}</span>: {row[`${EC_type}_data`].name}</li></Link>
            )
          }
        })

      }
    </ul>
    </>
);
  const renderDate = (cell) => {
    var date = new Date(cell);
    return date.toLocaleString("en-us");
  }

  const stagesFormatter = (cell, row) => {
    return (
      <select value={cell} style={{padding: 8, borderRadius: 4}} onChange={(val) => {console.log('changed'); window.alert('Editing lead stage from here is not functional yet.');}}>
        {
          ['Lead In','In Communication', 'Quote Generated', 'Contract Signed', 'Permitting', 'Installation Complete', 'Referral NOT QUALIFIED', 'Unresponsive'].map((stage_name, i) => {
          return (
          <option value={stage_name}>{stage_name}</option>
          )})
        }
      </select>




    )
  }


  const columns= [
    {
      text: 'Name',
      dataField: 'name',
      sort: true
    },
    {
      text: 'Referrers',
      dataField: 'referrers',
      sort: true,
      formatter: (cell, row) => renderHierarchyDataCell(row),
    },
    {
      text: 'City',
      dataField: 'city',
      sort: true
    },
    {
      text: 'Stage',
      dataField: 'stage',
      sort: true,
      formatter: (cell, row) => stagesFormatter(cell, row),
      // headerStyle: () => {
      //   return { width: "120px" };
      // }
    },
    {
      text: 'Created',
      dataField: 'createdAt',
      sort: true,
      type: 'date',
      formatter: (cell, row) => renderDate(cell),
    },
    {
      text: 'Updated',
      dataField: 'updatedAt',
      sort: true,
      type: 'date',
      formatter: (cell, row) => renderDate(cell),
    },
    {
      dataField: 'actions',
      text: 'Actions',
      isDummyField: true,
      csvExport: false,
      formatter: actionsFormatter
    }
  ];




  const handleOnSelect = (row, isSelect) => {
    console.log(row);
    console.log(isSelect);
    var currentlySelected = selectedRows;
      if (isSelect) {
        setSelectedRows([...selectedRows, row.id]);
      } else {
        setSelectedRows(selectedRows.filter(x => x !== row.id));
      }
    }

  const handleOnSelectAll = (isSelect, rows) => {
      const ids = rows.map(r => r.id);
      console.log(isSelect,ids);
      if (isSelect) {
        setSelectedRows(ids);
      } else {
        setSelectedRows([]);
      }
    }


  const unsubscribeToPendingPayoutsData = () => {
    firebase.database().ref('go').off('value');
  }
  const onSubmitCreateEC = async (data) => {
    console.log(data);
    try {
      var createECinPipedriveCallable = firebase
        .functions()
        .httpsCallable('createECinPipedriveCallable');
      var createECinPipedriveCallableResponse = await createECinPipedriveCallable({name: data.name, email: data.email});
      console.log('createECinPipedriveCallableResponse = '+JSON.stringify(createECinPipedriveCallableResponse));
    } catch (e) {
      console.log('createECinPipedriveCallable error');
      console.log(e.message);

    } finally {
      console.log('createECinPipedriveCallable finally');

    }
  }
  const MyExportCSV = (props) => {
    const handleClickedExportSelectedRows = () => {
      var rowsToExport = prepareForExportCSV((selectedRows && _.isArray(selectedRows) && selectedRows.length > 0 ? selectedRows : leads.map(row => row.id)));
      console.log('export rows ');
      console.log(rowsToExport);
      // props.onExport();
    };
    return (
      <div>
        <button className="btn btn-primary" onClick={ handleClickedExportSelectedRows }><ButtonIcon icon="file-export"></ButtonIcon>Export {(selectedRows && _.isArray(selectedRows) && selectedRows.length === 1 ? '1 payout' : (selectedRows && _.isArray(selectedRows) && selectedRows.length > 1 ? selectedRows.length + ' payouts' : 'all payouts'))} as CSV</button>
      </div>
    );
  };

  const prepareForExportCSV = (data) => {
    return data;

  }


    const renderMultiSelectActionButtons = () => (
      <>
      <div style={{alignSelf: 'center', marginRight: 12}}>With selected leads: </div>
        <OverlayTrigger
          key='edit-lead-key'
          placement='bottom'
          overlay={
            <Tooltip id={`tooltip-bottom`}>
              Edit leads for selected payouts
            </Tooltip>
          }
        >
          <ActionButton variant="secondary" onClick={() => { handleClickedEditLeadForSelectedRows(); }} disabled={(selectedRows && _.isArray(selectedRows) && selectedRows.length === 1 ? '' : 'disabled')}><ButtonIcon icon="pen" /> Edit</ActionButton>
        </OverlayTrigger>
        <OverlayTrigger
          key='cancel-lead-key'
          placement='bottom'
          overlay={
            <Tooltip id={`tooltip-bottom`}>
              Delete pending leads for selected payouts
            </Tooltip>
          }
        >
          <ActionButton variant="danger" onClick={() => { handleClickedDeleteLeadForSelectedRows(); }} disabled={(selectedRows && _.isArray(selectedRows) && selectedRows.length > 0 ? '' : 'disabled')}><ButtonIcon icon="trash-alt" /> Delete</ActionButton>
        </OverlayTrigger>
      </>
    )


      const renderModals = () => {
        if (!showConfirmModal) {
          return null;
        }
        if (leadIDsToEditEC1 && _.isArray(leadIDsToEditEC1) && leadIDsToEditEC1.length > 0) {
          var current_ec1 = {};
          current_ec1.id = leadIDsToEditEC1[0].ec1_id;
          current_ec1.name = leadIDsToEditEC1[0].ec1_name;
          leadIDsToEdit.some(lead => {
            if (lead.ec1_id !== current_ec1.id || lead.ec1_name !== current_ec1.name) {
              current_ec1 = {};
              return true;
            }
          });
          console.log('current_ec1 = '+JSON.stringify(current_ec1));
          return renderEditEC1Modal(current_ec1);
        }
        if (leadIDsToEdit && _.isArray(leadIDsToEdit) && leadIDsToEdit.length > 0) {
          return renderEditLeadModal();
        }
        if (leadIDsToDelete && _.isArray(leadIDsToDelete) && leadIDsToDelete.length > 0) {
          return renderDeleteLeadModal();
        }
      }


        const renderSendLeadModal = () => {
          var leads = [];
          if (leadIDsToEditEC1 && _.isArray(leadIDsToEditEC1) && leadIDsToEditEC1.length > 0) {
            leads = rowsForIds(leadIDsToEditEC1);
          }
          var leads = rowsForIds(leadIDsToEditEC1);

          if (!(leads && _.isArray(leads) && leads.length > 0)) {
            return (
              <Modal show={showConfirmModal} onHide={handleCloseConfirmModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>There was an error retrieving the leads</Modal.Body>
                <Modal.Footer>
                  <ActionButton variant="secondary" onClick={handleCloseConfirmModal}>
                    Close
                  </ActionButton>
                </Modal.Footer>
              </Modal>
            );
          }
        }


            const renderEditEC1Modal = (current_ec1 = null) => {
              var currentEC1Text = '';
              if (_.isObject(current_ec1) && _.isString(current_ec1.name) && current_ec1.name !== '') {
                currentEC1Text = ' (currently '+current_ec1.name+')';
              }
              const selectedNewEC1 = (event) => {
                if (_.isObject(event) && _.isObject(event.target) && _.isString(event.target.value) && event.target.value !== '' && event.target.value !== current_ec1.id) {
                  setEditEC1SelectedEC1ID(event.target.value);
                } else {
                  setEditEC1SelectedEC1ID(null);
                }
              }
              return (
                <Modal show={showConfirmModal} onHide={handleCloseConfirmModal}>
                  <Modal.Header closeButton>
                    <Modal.Title>Change EC1 Assigned to lead{leadIDsToEditEC1 && _.isArray(leadIDsToEditEC1) && leadIDsToEditEC1.length > 1 ? 's' : ''}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    { loadingAllEC1s ? (
                      <div>Loading EC1s</div>
                    ) : (
                      <>
                      <p style={{marginBottom: 4, fontWeight:600}}>Select new EC1{currentEC1Text}:</p>
                      <select style={{padding: 8, borderRadius: 4, marginBottom: 20}} onChange={selectedNewEC1}>
                        {
                          allEC1s.map((ec1_data, i) => {
                          return (
                          <option value={ec1_data.id} selected={ec1_data.id === current_ec1.id ? "selected" : ""}>{ec1_data.name}</option>
                          )})
                        }
                      </select>
                    <div style={{color: 'red'}}>This change will affect the lead structure, <span style={{fontWeight:900}}>payouts</span>, and visibility of the following lead{leadIDsToEditEC1 && _.isArray(leadIDsToEditEC1) && leadIDsToEditEC1.length > 1 ? 's' : ''}:</div>
                    { leadIDsToEditEC1 && _.isArray(leadIDsToEditEC1) && leadIDsToEditEC1.length > 0 && (
                      <ul style={{marginLeft: 18, marginTop: 10}}>
                          {
                            leadIDsToEditEC1.map((lead, i) => {
                              return (
                                <li>{lead.name} -- <span style={{fontWeight:900}}>EC1</span>: {lead.ec1_name || 'unassigned'}</li>
                              )})
                          }
                      </ul>
                    )}
                    </>
                    )}
                  </Modal.Body>
                  <Modal.Footer>
                    <ActionButton variant="danger" onClick={handleCloseConfirmModal}>
                      Cancel
                    </ActionButton>
                    <ActionButton variant={editEC1SelectedEC1ID === null ? 'secondary': 'primary'} disabled={editEC1SelectedEC1ID === null ? 'disabled': ''} onClick={handleConfirmEditLeadEC1}>
                      Edit Lead
                    </ActionButton>
                  </Modal.Footer>
                </Modal>
              )
            }

            const renderEditLeadModal = () => (
              <Modal show={showConfirmModal} onHide={handleCloseConfirmModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Edit lead</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div>This is not implemented yet</div>
                </Modal.Body>
                <Modal.Footer>
                  <ActionButton variant="danger" onClick={handleCloseConfirmModal}>
                    Cancel
                  </ActionButton>
                  <ActionButton variant="secondary" onClick={handleConfirmEditLead}>
                    Edit Lead
                  </ActionButton>
                </Modal.Footer>
              </Modal>
            );

            const renderDeleteLeadModal = () => (
              <Modal show={showConfirmModal} onHide={handleCloseConfirmModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Send lead{leadIDsToDelete && _.isArray(leadIDsToDelete) && leadIDsToDelete.length > 1 ? 's' : ''}?</Modal.Title>
                </Modal.Header>
                <Modal.Body>Would you like to delete the lead to {leadIDsToDelete}?<br/><br/><h3>This cannot be un-done.</h3></Modal.Body>
                <Modal.Footer>
                  <ActionButton variant="secondary" onClick={handleCloseConfirmModal}>
                    Cancel
                  </ActionButton>
                  <ActionButton variant="danger" onClick={handleConfirmDeleteLead}>
                    Delete Lead
                  </ActionButton>
                </Modal.Footer>
              </Modal>
            );
            const rowsForIds = (ids) => {
              var payouts = leads.filter ( row => ids.includes(row.id) );
              if (payouts && _.isArray(payouts) && payouts.length > 0) {
                console.log('rowsForIds('+JSON.stringify(ids)+')');
                console.info(payouts);
                return payouts;
              }
              return [];
            }



              const handleConfirmEditLeadEC1 = async () => {
                if (editEC1SelectedEC1ID && _.isString(editEC1SelectedEC1ID) && editEC1SelectedEC1ID !== '' && _.isArray(leadIDsToEditEC1) && leadIDsToEditEC1.length > 0) {
                  var leadData = leadIDsToEditEC1.map(lead => {
                    return {
                      lead_id: lead.id,
                      new_ec1_id: editEC1SelectedEC1ID,
                      old_ec1_id: lead.EC1,
                      lead_source_user_id: lead.parent_id
                    }
                  });

                  var changeEC1ForLeadsCallable = firebase
                  .functions()
                  .httpsCallable('changeEC1ForLeadsCallable');
                  changeEC1ForLeadsCallable(leadData)
                  .then((getLeadsResponse) => {
                    console.log(getLeadsResponse);
                    setTimeout(() => {
                      window.location.reload();
                    }, 2000);
                  })
                  .catch((getLeadsCallableError) => {
                    console.log('getLeadsCallableError = '+getLeadsCallableError.message);
                  });
                } else {
                  window.alert('There was an error editing the EC1s for the selected leads');
                }

                setLeadIDsToEditEC1([]);
                handleCloseConfirmModal();
              }

              const handleConfirmEditLead = () => {
                console.log('edit leads for '+JSON.stringify(leadIDsToEdit));
                setLeadIDsToEdit([]);
                handleCloseConfirmModal();
              }

              const handleConfirmDeleteLead = () => {
                console.log('send leads for '+JSON.stringify(leadIDsToDelete));
                setLeadIDsToDelete([]);
                handleCloseConfirmModal();
              }
              const selectRow = {
                mode: 'checkbox',
                clickToSelect: false,
                selected: selectedRows,
                onSelect: handleOnSelect,
                onSelectAll: handleOnSelectAll
              };

  return (
    <ToolkitProvider
      keyField="id"
      data={ leads }
      columns={ columns }
      exportCSV={ {
        fileName: 'custom.csv',
        separator: '|',
        ignoreHeader: true,
        noAutoBOM: false
      } }
    >
      {
        props =>
        (
          <div>
            <SelectedItemsActionsWrapper>
              <MyExportCSV />
              <div style={{flex:1, flexBasis: '100%', maxWidth: 500, width: '100%'}}>
              <SearchBar style={{width: '100%'}} placeholder="Search for a user or lead"/>
              </div>
                <div>
              {renderMultiSelectActionButtons()}
              </div>
            </SelectedItemsActionsWrapper>
            <hr />
            <BootstrapTable keyField='id' data={leads} columns={columns} selectRow={selectRow} />
              {
                renderModals()
              }
          </div>
        )
      }
    </ToolkitProvider>
  )
}

const SelectedItemsActionsWrapper = styled.div`
display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 40px 40px 8px;
`;

const ActionButton = styled(Button)`
  margin: 0px 4px;
`;

const ButtonIcon = styled(FontAwesomeIcon)`
margin: 0px 4px;
`;

export default AdminLeadManagement;

import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import firebase, { cronDatabase } from "../../../../config/firebase.js";
import { roleTypesForCapitalization } from "../../../../config/config.js";
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUser, faUsers, faPen, faTrashAlt, faFileExport, faFunnelDollar } from "@fortawesome/free-solid-svg-icons";
import ListGroup from 'react-bootstrap/ListGroup'
import { capitalize } from '../../../../utils/strings';

import _ from "lodash";

import "./AdminNotificationsByUserDetail.min.css";

library.add(faUser, faUsers, faPen, faTrashAlt, faFileExport, faFunnelDollar);

const { ExportCSVButton } = CSVExport;
const { SearchBar } = Search;

const TEST_LOCAL = false;

function AdminNotificationsByUserDetail(props) {
  const { register, handleSubmit, errors, reset, watch, setError } = useForm(); // initialize the hook
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [paymentIDsToSend, setPaymentIDsToSend] = useState([]);
  const [paymentIDsToEdit, setPaymentIDsToEdit] = useState([]);
  const [paymentIDsToDelete, setPaymentIDsToDelete] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [userType, setUserType] = useState(null);
  const [userName, setUserName] = useState('');

  useEffect(() => {
    console.log(props);
    // if (!TEST_LOCAL) {
    //   try {
    //     var result = loadNotifications();
    //     console.log(result);
    //   } catch (e) {
    //     console.log('got an error loading data');
    //     console.error(e.message);
    //   } finally {
    //     console.log('finally done with useEffect');
    //   }
    //   // return unsubscribeToPendingPayoutsData();
    // }
    try {
      if (_.isString(props.userID) && props.userID !== '') {
        loadNotifications(props.userID);
      } else {
        setNotifications([]);
      }
    } catch (e) {
      console.log('got an error loading data');
      console.error(e.message);
    } finally {
      console.log('finally done with useEffect');
    }
  }, [props]);

  // const unsubscribeToUsersData = () => {
  //   firebase.database().ref('users').off('value');
  // }



  const loadNotifications = async (userID) => {
    try {
      console.log('before '+userID);
      firebase.database().ref('users').child(userID).child('name').once('value').then((nameSnapshot) => {
        if (nameSnapshot.exists()) {
          if (_.isString(nameSnapshot.val()) && nameSnapshot.val() !== '') {
            setUserName(nameSnapshot.val());
          }
        }
      });
      firebase.database().ref('notifications').child(userID).orderByChild('createdAt').once('value').then((snapshot) => {
        var notificationData = [];
        console.log('here');
        if (snapshot.exists()) {
          if (_.isObject(snapshot.val()) && Object.keys(snapshot.val()).length > 0) {
            for (const [key, value] of Object.entries(snapshot.val())) {
              var u = _.pick(value,['createdAt','message','title','email']);
              if (_.isObject(u) && Object.keys(u).length > 0) {
                notificationData.push(u);
              }
            }
          }

          console.log(notificationData);
          setNotifications(notificationData.sort((a, b) => a.sendAt > b.sendAt && 1 || -1));
          // setNotifications(snapshot.val());
        } else {
          console.log('no snapshot');
        }
      });
      console.log('after');
    } catch (e) {
      console.log('an error occurred');
      console.log(e.message);
    } finally {
      console.log('finally');
    }

    // try {
    //   var stripeusersCallable = firebase.functions().httpsCallable('stripeusersCallable');
    //   setNotifications(await stripeusersCallable());
    //
    // } catch (e) {
    //
    //   console.log('error');
    //     console.log(e.message);
    // } finally {
    //   console.log('finally');
    // }
    const processNotifications = (snapshot) => {
      if (snapshot.exists()) {
        var userData = [];
        if (_.isObject(snapshot.val()) && Object.keys(snapshot.val()).length > 0) {
          for (const [key, value] of Object.entries(snapshot.val())) {
            var u = _.pick(value,['name','email','city','state','id','type','referral_code','org_id']);
            if (_.isString(u.state)) {
              u.city = u.city + ', '+u.state;
              delete u.state;
            }
            if (!(_.isString(u.id) && u.id !== '')) {
              u.id = key;
            }
            console.log(`${key}: ${u}`);
            if (_.isObject(u) && Object.keys(u).length > 0) {
              userData.push(u);
            }
          }
        }

        console.log(userData);
        setNotifications(userData.sort((a, b) => a.name > b.name && 1 || -1));
        // setNotifications(snapshot.val());
      }
    };
  }


    const handleClickedViewInDBForRow = (row_id) => {
      console.log('here');
      setSelectedRows([]);
      setPaymentIDsToSend([row_id]);
      setPaymentIDsToEdit([]);
      setPaymentIDsToDelete([]);

      setShowConfirmModal(true);
    }

    const handleClickedEditPaymentForRow = (row_id) => {
      setSelectedRows([]);
      setPaymentIDsToSend([]);
      setPaymentIDsToEdit([row_id]);
      setPaymentIDsToDelete([]);
      setShowConfirmModal(true);
    }

    const handleClickedDeletePaymentForRow = (row_id) => {
      setSelectedRows([]);
      setPaymentIDsToSend([]);
      setPaymentIDsToEdit([]);
      setPaymentIDsToDelete([row_id]);
      setShowConfirmModal(true);
    }

    const handleClickedSendPaymentForSelectedRows = () => {
      setPaymentIDsToSend(selectedRows);
      setPaymentIDsToEdit([]);
      setPaymentIDsToDelete([]);
      setShowConfirmModal(true);
    }

    const handleClickedEditPaymentForSelectedRows = () => {
      setPaymentIDsToSend([]);
      setPaymentIDsToEdit(selectedRows);
      setPaymentIDsToDelete([]);
      setShowConfirmModal(true);
    }

    const handleClickedDeletePaymentForSelectedRows = () => {
      setPaymentIDsToSend([]);
      setPaymentIDsToEdit([]);
      setPaymentIDsToDelete(selectedRows);
      setShowConfirmModal(true);
    }

    const handleCloseConfirmModal = () => {
      setPaymentIDsToSend([]);
      setShowConfirmModal(false);
    }



  const actionsFormatter = (cell, row) => (
      <>
        <OverlayTrigger
          key={`view-in-db-team-${row.id}`}
          placement='bottom'
          overlay={
            <Tooltip id={`tooltip-bottom`}>
              View user's leads
            </Tooltip>
          }
        >
        <Link to={`/leads/${row.id}`}>
          <ActionButton variant="success"><ButtonIcon icon="funnel-dollar" /></ActionButton></Link>
        </OverlayTrigger>
        <OverlayTrigger
          key='view-in-db'
          placement='bottom'
          overlay={
            <Tooltip id={`tooltip-bottom`}>
              View user in Database
            </Tooltip>
          }
        >
        <a href={`https://console.firebase.google.com/u/0/project/isp-2-0-app/database/isp-2-0-app/data/~2Fusers~2F${row.id}`} target="_blank">
          <ActionButton variant="secondary"><ButtonIcon icon="user" /></ActionButton></a>
        </OverlayTrigger>
        <OverlayTrigger
          key='view-in-db-team'
          placement='bottom'
          overlay={
            <Tooltip id={`tooltip-bottom`}>
              View user's team in Database
            </Tooltip>
          }
        >
        <a href={`https://console.firebase.google.com/u/0/project/isp-2-0-app/database/isp-2-0-app/data/~2Fteams~2F${row.id}`} target="_blank">
          <ActionButton variant="secondary"><ButtonIcon icon="users" /></ActionButton></a>
        </OverlayTrigger>
        <OverlayTrigger
          key='edit-payment-key'
          placement='bottom'
          overlay={
            <Tooltip id={`tooltip-bottom`}>
              Edit Payment
            </Tooltip>
          }
        >
          <ActionButton variant="secondary" onClick={() => { handleClickedEditPaymentForRow(row.id); }}><ButtonIcon icon="pen" /></ActionButton>
        </OverlayTrigger>
        <OverlayTrigger
          key='cancel-payment-key'
          placement='bottom'
          overlay={
            <Tooltip id={`tooltip-bottom`}>
              Delete pending payment
            </Tooltip>
          }
        >
          <ActionButton variant="danger" onClick={() => { handleClickedDeletePaymentForRow(row.id); }}><ButtonIcon icon="trash-alt" /></ActionButton>
        </OverlayTrigger>
      </>
);


  const columns= [
    {
      text: 'Sent',
      dataField: 'createdAt',
      sort: true,
      type: 'date',
      formatter: (cell, row) => renderDate(cell),
      headerStyle: () => {
        return { width: "220px" };
      }
    },
    {
      text: 'Message',
      dataField: 'message',
      sort: false,
      formatter: (cell, row) => renderMessage(row),
    }
  ];

  const renderMessage = (row) => (
    <>
      {
        (_.isString(row.title) && row.title !== '') && (
          <NotificationTitleText>{row.title}</NotificationTitleText>
        )
      }
      {
        (_.isString(row.message) && row.message !== '') && (
          <NotificationMessageText>{row.message}</NotificationMessageText>
        )
      }
    </>
  )
  const renderDate = (cell) => {
    var date = new Date(cell);
    return date.toLocaleString("en-us");
  }


  const handleOnSelect = (row, isSelect) => {
    console.log(row);
    console.log(isSelect);
    var currentlySelected = selectedRows;
      if (isSelect) {
        setSelectedRows([...selectedRows, row.id]);
      } else {
        setSelectedRows(selectedRows.filter(x => x !== row.id));
      }
    }

  const handleOnSelectAll = (isSelect, rows) => {
      const ids = rows.map(r => r.id);
      console.log(isSelect,ids);
      if (isSelect) {
        setSelectedRows(ids);
      } else {
        setSelectedRows([]);
      }
    }


  const unsubscribeToPendingPayoutsData = () => {
    firebase.database().ref('go').off('value');
  }
  const onSubmitCreateEC = async (data) => {
    console.log(data);
    try {
      var createECinPipedriveCallable = firebase
        .functions()
        .httpsCallable('createECinPipedriveCallable');
      var createECinPipedriveCallableResponse = await createECinPipedriveCallable({name: data.name, email: data.email});
      console.log('createECinPipedriveCallableResponse = '+JSON.stringify(createECinPipedriveCallableResponse));
    } catch (e) {
      console.log('createECinPipedriveCallable error');
      console.log(e.message);

    } finally {
      console.log('createECinPipedriveCallable finally');

    }
  }
  const MyExportCSV = (props) => {
    const handleClickedExportSelectedRows = () => {
      var rowsToExport = prepareForExportCSV((selectedRows && _.isArray(selectedRows) && selectedRows.length > 0 ? selectedRows : notifications.map(row => row.id)));
      console.log('export rows ');
      console.log(rowsToExport);
      // props.onExport();
    };
    return (
      <div>
        <button className="btn btn-primary" onClick={ handleClickedExportSelectedRows }><ButtonIcon icon="file-export"></ButtonIcon>Export {(selectedRows && _.isArray(selectedRows) && selectedRows.length === 1 ? '1 notification' : (selectedRows && _.isArray(selectedRows) && selectedRows.length > 1 ? selectedRows.length + ' notifications' : 'all notifications'))} as CSV</button>
      </div>
    );
  };

  const prepareForExportCSV = (data) => {
    return data;

  }


    const renderMultiSelectActionButtons = () => (
      <>
      <div style={{alignSelf: 'center', marginRight: 12}}>With selected payments: </div>
        <OverlayTrigger
          key='send-payment-key'
          placement='bottom'
          overlay={
            <Tooltip id={`tooltip-bottom`}>
              Send payments for selected payouts via Stripe
            </Tooltip>
          }
        >
          <ActionButton variant="success" onClick={() => { handleClickedSendPaymentForSelectedRows(); }} disabled={(selectedRows && _.isArray(selectedRows) && selectedRows.length > 0 ? '' : 'disabled')}><ButtonIcon icon="file-invoice-dollar" /> Send $</ActionButton>
        </OverlayTrigger>
        <OverlayTrigger
          key='edit-payment-key'
          placement='bottom'
          overlay={
            <Tooltip id={`tooltip-bottom`}>
              Edit payments for selected payouts
            </Tooltip>
          }
        >
          <ActionButton variant="secondary" onClick={() => { handleClickedEditPaymentForSelectedRows(); }} disabled={(selectedRows && _.isArray(selectedRows) && selectedRows.length === 1 ? '' : 'disabled')}><ButtonIcon icon="pen" /> Edit</ActionButton>
        </OverlayTrigger>
        <OverlayTrigger
          key='cancel-payment-key'
          placement='bottom'
          overlay={
            <Tooltip id={`tooltip-bottom`}>
              Delete pending payments for selected payouts
            </Tooltip>
          }
        >
          <ActionButton variant="danger" onClick={() => { handleClickedDeletePaymentForSelectedRows(); }} disabled={(selectedRows && _.isArray(selectedRows) && selectedRows.length > 0 ? '' : 'disabled')}><ButtonIcon icon="trash-alt" /> Delete</ActionButton>
        </OverlayTrigger>
      </>
    )


      const renderModals = () => {
        if (!showConfirmModal) {
          return null;
        }
        if (paymentIDsToSend && _.isArray(paymentIDsToSend) && paymentIDsToSend.length > 0) {
          return renderSendPaymentModal();
        }
        if (paymentIDsToEdit && _.isArray(paymentIDsToEdit) && paymentIDsToEdit.length > 0) {
          return renderEditPaymentModal();
        }
        if (paymentIDsToDelete && _.isArray(paymentIDsToDelete) && paymentIDsToDelete.length > 0) {
          return renderDeletePaymentModal();
        }
      }


        const renderSendPaymentModal = () => {
          var payments = [];
          if (paymentIDsToSend && _.isArray(paymentIDsToSend) && paymentIDsToSend.length > 0) {
            payments = rowsForIds(paymentIDsToSend);
          }
          var payments = rowsForIds(paymentIDsToSend);

          if (!(payments && _.isArray(payments) && payments.length > 0)) {
            return (
              <Modal show={showConfirmModal} onHide={handleCloseConfirmModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>There was an error retrieving the payments</Modal.Body>
                <Modal.Footer>
                  <ActionButton variant="secondary" onClick={handleCloseConfirmModal}>
                    Close
                  </ActionButton>
                </Modal.Footer>
              </Modal>
            );
          }
        }


            const renderEditPaymentModal = () => (
              <Modal show={showConfirmModal} onHide={handleCloseConfirmModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Edit payment{paymentIDsToEdit && _.isArray(paymentIDsToEdit) && paymentIDsToEdit.length > 1 ? 's' : ''}?</Modal.Title>
                </Modal.Header>
                <Modal.Body>Would you like to edit the payment {paymentIDsToEdit}?</Modal.Body>
                <Modal.Footer>
                  <ActionButton variant="danger" onClick={handleCloseConfirmModal}>
                    Cancel
                  </ActionButton>
                  <ActionButton variant="secondary" onClick={handleConfirmEditPayment}>
                    Edit Payment
                  </ActionButton>
                </Modal.Footer>
              </Modal>
            );

            const renderDeletePaymentModal = () => (
              <Modal show={showConfirmModal} onHide={handleCloseConfirmModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Send payment{paymentIDsToDelete && _.isArray(paymentIDsToDelete) && paymentIDsToDelete.length > 1 ? 's' : ''}?</Modal.Title>
                </Modal.Header>
                <Modal.Body>Would you like to delete the payment to {paymentIDsToDelete}?<br/><br/><h3>This cannot be un-done.</h3></Modal.Body>
                <Modal.Footer>
                  <ActionButton variant="secondary" onClick={handleCloseConfirmModal}>
                    Cancel
                  </ActionButton>
                  <ActionButton variant="danger" onClick={handleConfirmDeletePayment}>
                    Delete Payment
                  </ActionButton>
                </Modal.Footer>
              </Modal>
            );
            const rowsForIds = (ids) => {
              var payouts = notifications.filter ( row => ids.includes(row.id) );
              if (payouts && _.isArray(payouts) && payouts.length > 0) {
                console.log('rowsForIds('+JSON.stringify(ids)+')');
                console.info(payouts);
                return payouts;
              }
              return [];
            }



              const handleConfirmSendPayment = () => {
                console.log('send payments for '+JSON.stringify(paymentIDsToSend));
                setPaymentIDsToSend([]);
                handleCloseConfirmModal();
              }

              const handleConfirmEditPayment = () => {
                console.log('edit payments for '+JSON.stringify(paymentIDsToEdit));
                setPaymentIDsToEdit([]);
                handleCloseConfirmModal();
              }

              const handleConfirmDeletePayment = () => {
                console.log('send payments for '+JSON.stringify(paymentIDsToDelete));
                setPaymentIDsToDelete([]);
                handleCloseConfirmModal();
              }
              const selectRow = {
                mode: 'checkbox',
                clickToSelect: false,
                selected: selectedRows,
                onSelect: handleOnSelect,
                onSelectAll: handleOnSelectAll
              };

  return (
    <ToolkitProvider
      keyField="id"
      data={ notifications }
      columns={ columns }
      exportCSV={ {
        fileName: 'custom.csv',
        separator: '|',
        ignoreHeader: true,
        noAutoBOM: false
      } }
    >
      {
        props =>
        (
          <div>
            <TableHeader>{`${(_.isString(userName) && userName !== '' ? userName + "'s " : '')}notifications`}</TableHeader>
            <SelectedItemsActionsWrapper>
              <MyExportCSV />
              <div style={{flex:1, flexBasis: '100%', maxWidth: 500, width: '100%'}}>
              <SearchBar style={{width: '100%'}} placeholder="Search notifications"/>
              </div>
                <div>
              </div>
            </SelectedItemsActionsWrapper>
            <hr />
            <BootstrapTable keyField='id' data={notifications} columns={columns} selectRow={selectRow} />
              {
                renderModals()
              }
          </div>
        )
      }
    </ToolkitProvider>
  )
}

const NotificationTitleText = styled.p`
  font-weight: 800;
  margin-bottom: 0;
`;
const NotificationMessageText = styled.p`

`;
const TableHeader = styled.h3`
  text-align: center;
  margin: 20px 0;
  font-weight: 600;
`;


const SelectedItemsActionsWrapper = styled.div`
display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 40px 40px 8px;
`;

const ActionButton = styled(Button)`
  margin: 0px 4px;
`;

const ButtonIcon = styled(FontAwesomeIcon)`
margin: 0px 4px;
`;

export default AdminNotificationsByUserDetail;

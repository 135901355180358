import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import firebase from "../../config/firebase.js";
import { useForm } from 'react-hook-form';
import EmbedForm from "../EmbedForm/EmbedForm";
import styled from 'styled-components';
import { getSearchParameters } from "../../utils/url.js"
import { electricBillDictionary, electricBillCutoffs } from "../../config/config.js";

import _ from "lodash";

import "./EmbedFormIframe.min.css";

function EmbedFormIframe(props) {
  const [formType, setFormType] = useState('quote');
  const availableFormTypes = ['quote', 'advocate'];
  const [pathElectricBill, setPathElectricBill] = useState(null);

  useEffect(() => {
    if (props && _.isObject(props) && _.isString(props.formType) && availableFormTypes.includes(props.formType.toLowerCase())) {
      setFormType(props.formType.toLowerCase());
    }
    if (props && _.isObject(props) && _.isObject(props.location) && _.isString(props.location.search)) {
      var params = getSearchParameters(props.location);
      if (params && _.isObject(params) && params.electricBill !== null && (_.isFinite(params.electricBill) || (_.isString(params.electricBill) && params.electricBill !== '' && !isNaN(params.electricBill)))) {
        setPathElectricBill(electricBillForPath(params.electricBill));
      }
    }
  }, [props.formType, (props.location || {search: null}).search]);

  const electricBillForPath = (pathEB) => {
    if (pathEB) {
      pathEB = parseInt(pathEB);
      var eB = null;
      electricBillCutoffs.some(function(arrVal) {
        if (arrVal > pathEB) {
          eB = electricBillDictionary[arrVal];
          return true;
        }
        return false;
      });
      return eB;
    }
    return null;
  }

  return (
      <IFrameContainer className="iframe">
        <IFrameFormWrapper>
          <EmbedForm formType={formType} isIframe={true} pathElectricBill={pathElectricBill}/>
        </IFrameFormWrapper>
      </IFrameContainer>
  )
}

export default EmbedFormIframe;

const IFrameContainer = styled.div`
  width: 100%;
  padding: 20px;
`;

const IFrameFormWrapper = styled.div`
  max-width: 480px;
  margin: 0px auto;
  width: 100%;
  padding: 32px;
  background-color: rgb(255, 255, 255);
  color: rgb(31, 31, 31);
  box-shadow: rgb(0 0 0 / 8%) 0px 2px 8px;
  border-radius: 20px;
`;

const getSearchParameters = (location) => {
    var prmstr = location.search.substr(1);
    return prmstr != null && prmstr != "" ? transformToAssocArray(prmstr) : {};
}

exports.getSearchParameters = getSearchParameters;

const getPathRouteArray = (location) => {
  var pathname = location.pathname.substr(1);

  return pathname.split('/');
}

exports.getPathRouteArray = getPathRouteArray;

const transformToAssocArray = ( prmstr ) => {
    var params = {};
    var prmarr = prmstr.split("&");
    for ( var i = 0; i < prmarr.length; i++) {
        var tmparr = prmarr[i].split("=");
        params[tmparr[0]] = tmparr[1];
    }
    return params;
}

import React, { useState, useEffect } from 'react';
import { asyncisLogin } from '../../utils/userSession';
import "./Header.css";
import { NavLink } from "react-router-dom";

function Header({ history, ...props }) {
  const [isGettingLogin, setIsGettingLogin] = useState(true);
  const [isLogin, setIsLogin] = useState(false);



  useEffect(() => {
    getIsLoggedIn();
  }, []);

  const getIsLoggedIn = async () => {
    await asyncisLogin((isL) => {setIsLogin(isL); setIsGettingLogin(false)});
  }
  return (
    <header className="App-header">
      <div class="wrapper">
        <img width="98px" height="100px" style={{margin: '0px auto'}} src="/assets/isp.png" alt="logo" />
        <ul>
          { isLogin ? (
            <>
          <li>
            <NavLink to="/users" activeClassName="selected" exact>All Users</NavLink>
          </li>
          <li>
            <NavLink to="/users/ec1s" activeClassName="selected" exact>EC1s</NavLink>
          </li>
          <li>
            <NavLink to="/users/ec1s/rotation" activeClassName="selected" exact>EC1 Rotation</NavLink>
          </li>
          <li>
            <NavLink to="/leads" activeClassName="selected" exact>Leads</NavLink>
          </li>
          <li>
            <NavLink to="/notifications" activeClassName="selected" exact>Notifications</NavLink>
          </li>
          <li>
            <NavLink to="/pending-payments" activeClassName="selected" exact>Payments</NavLink>
          </li>
          </>
        ) : (
          <li>
          </li>
        )}
        </ul>
      </div>
    </header>
  );
}


export default Header;

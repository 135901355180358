import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import firebase, { adminDatabase } from "../../config/firebase.js";
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUser, faUsers, faPen, faTrashAlt, faFileExport, faFunnelDollar, faHandPointRight } from "@fortawesome/free-solid-svg-icons";
import ListGroup from 'react-bootstrap/ListGroup'

import _ from "lodash";

import "./AdminEC1Rotation.min.css";

library.add(faUser, faUsers, faPen, faTrashAlt, faFileExport, faFunnelDollar, faHandPointRight);

const { ExportCSVButton } = CSVExport;
const { SearchBar } = Search;

const TEST_LOCAL = false;

function AdminEC1Rotation(props) {
  const { register, handleSubmit, errors, reset, watch, setError } = useForm(); // initialize the hook
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [paymentIDsToSend, setPaymentIDsToSend] = useState([]);
  const [paymentIDsToEdit, setPaymentIDsToEdit] = useState([]);
  const [paymentIDsToDelete, setPaymentIDsToDelete] = useState([]);
  const [users, setUsers] = useState([]);
  const [userType, setUserType] = useState(null);

  useEffect(() => {
    console.log(TEST_LOCAL);
    // if (!TEST_LOCAL) {
    //   try {
    //     var result = loadUsersData();
    //     console.log(result);
    //   } catch (e) {
    //     console.log('got an error loading data');
    //     console.error(e.message);
    //   } finally {
    //     console.log('finally done with useEffect');
    //   }
    //   // return unsubscribeToPendingPayoutsData();
    // }
    try {
      loadEC1Rotation();
    } catch (e) {
      console.log('got an error loading data');
      console.error(e.message);
    } finally {
      console.log('finally done with useEffect');
    }
  }, [props]);

  // const unsubscribeToUsersData = () => {
  //   firebase.database().ref('users').off('value');
  // }



  const loadEC1Rotation = async (userType = null) => {
    console.log('loadUsersData');
    try {
        adminDatabase.child('ec-rotation').once('value', (snapshot) => {
          if (snapshot.exists()) {
            var snapshotVal = snapshot.val();
            var userData = [];
            var currentIndex = 0;
            if (_.isObject(snapshotVal) && Object.keys(snapshotVal).length > 0) {
              if (_.isObject(snapshotVal.next) && snapshotVal.next.index && !isNaN(snapshotVal.next.index)) {
                currentIndex = parseInt(snapshotVal.next.index);
              }
              if (_.isArray(snapshotVal.order) && snapshotVal.order.length > 0) {
                for (var i = 0; i < snapshotVal.order.length; i++) {
                  var u = _.pick(snapshotVal.order[i],['name','u_id','last_assigned'])
                  u.id = u.u_id;
                  u.data_key = `${i}--${u.u_id}`;
                  u.next = (currentIndex === i);
                  u.index = i;
                  u.display_index = i + 1;
                  if (_.isObject(u.last_assigned)) {
                    u.last_assigned.lead_parent_id = u.u_id;
                  }
                  userData.push(u);
                }
              }
            }

            console.log(userData);
            setUsers(userData.sort((a, b) => a.index > b.index && 1 || -1));
            // setUsers(snapshot.val());
          }
        });
      console.log('after');
    } catch (e) {
      console.log('an error occurred');
      console.log(e.message);
    } finally {
      console.log('finally');
    }

  }


    const handleClickedViewInDBForRow = (row_id) => {
      console.log('here');
      setSelectedRows([]);
      setPaymentIDsToSend([row_id]);
      setPaymentIDsToEdit([]);
      setPaymentIDsToDelete([]);

      setShowConfirmModal(true);
    }

    const handleClickedEditPaymentForRow = (row_id) => {
      setSelectedRows([]);
      setPaymentIDsToSend([]);
      setPaymentIDsToEdit([row_id]);
      setPaymentIDsToDelete([]);
      setShowConfirmModal(true);
    }

    const handleClickedDeletePaymentForRow = (row_id) => {
      setSelectedRows([]);
      setPaymentIDsToSend([]);
      setPaymentIDsToEdit([]);
      setPaymentIDsToDelete([row_id]);
      setShowConfirmModal(true);
    }

    const handleClickedSendPaymentForSelectedRows = () => {
      setPaymentIDsToSend(selectedRows);
      setPaymentIDsToEdit([]);
      setPaymentIDsToDelete([]);
      setShowConfirmModal(true);
    }

    const handleClickedEditPaymentForSelectedRows = () => {
      setPaymentIDsToSend([]);
      setPaymentIDsToEdit(selectedRows);
      setPaymentIDsToDelete([]);
      setShowConfirmModal(true);
    }

    const handleClickedDeletePaymentForSelectedRows = () => {
      setPaymentIDsToSend([]);
      setPaymentIDsToEdit([]);
      setPaymentIDsToDelete(selectedRows);
      setShowConfirmModal(true);
    }

    const handleCloseConfirmModal = () => {
      setPaymentIDsToSend([]);
      setShowConfirmModal(false);
    }
    const renderDate = (d) => {
      var date = new Date(d);
      return date.toLocaleString("en-us");
    }


  const actionsFormatter = (cell, row) => (
      <>
        <OverlayTrigger
          key={`make-next-${row.id}`}
          placement='bottom'
          overlay={
            <Tooltip id={`tooltip-bottom`}>
              Make next up
            </Tooltip>
          }
        >
          <ActionButton variant="success"><ButtonIcon icon="hand-point-left" /></ActionButton>
        </OverlayTrigger>
      </>
);

  const currentFormatter = (cell, row) => {
    console.log(cell);
    return (
    <>
      { cell ? (
        <FontAwesomeIcon icon="hand-point-right" style={{color: '#28a745',verticalAlign: 'middle', width: '100%','textAlign': 'center', fontSize: 32, marginTop: 3, marginBottom: 3}}/>
      ) : (
        <CurrentHoverAction>
          <ActionButton variant="success" onClick={() => { handleClickedMakeNextEC1InRotation(row.index); }}><ButtonIcon icon="hand-point-right" /></ActionButton>
        </CurrentHoverAction>
      )

      }
    </>
  )}

  const handleClickedMakeNextEC1InRotation = (index) => {
    console.log(`handleClickedMakeNextEC1InRotation(${JSON.stringify(index)})`);
    var response = firebase.functions().httpsCallable('makeECRotationOrderNextByIndexCallable')(index)
      .then((getLeadsResponse) => {
        console.log(getLeadsResponse);
        if (getLeadsResponse && _.isObject(getLeadsResponse) && (getLeadsResponse.data === true || getLeadsResponse.data === 'true')) {
          console.log('success');
          loadEC1Rotation();
        } else {
          window.alert('There was an error resetting the EC order');
        }
      })
      .catch((getLeadsCallableError) => {
        console.log('getLeadsCallableError = '+getLeadsCallableError.message);
        window.alert('There was an error resetting the EC order');
      });
  }

  const lastAssignedFormatter = (cell, row) => {
    console.log(cell);
    if (!cell) return null;
    return (
      <>
        <a href={`https://console.firebase.google.com/u/0/project/isp-2-0-app/database/isp-2-0-app/data/leads/${cell.lead_parent_id}/${cell.lead_id}`} target="_blank">{renderDate(cell.createdAt)} -- {cell.name}</a>
      </>
    )
  }

  const columns= [
    {
      text: 'Up Next',
      dataField: 'next',
      headerStyle: () => {
        return { width: "80px" };
      },
      formatter: currentFormatter
    },
    {
      text: 'Order',
      dataField: 'display_index',
      sort: true,
      headerStyle: () => {
        return { width: "80px" };
      }
    },
    {
      text: 'Name',
      dataField: 'name'
    },
    // {
    //   text: 'ID',
    //   dataField: 'u_id',
    //   sort: true
    // },
    {
      text: 'Last Assigned Lead',
      dataField: 'last_assigned',
      formatter: lastAssignedFormatter,
    // },
    // {
    //   dataField: 'actions',
    //   text: 'Make next',
    //   isDummyField: true,
    //   csvExport: false,
    //   formatter: actionsFormatter
    }
  ];




  const handleOnSelect = (row, isSelect) => {
    console.log(row);
    console.log(isSelect);
    var currentlySelected = selectedRows;
      if (isSelect) {
        setSelectedRows([...selectedRows, row.id]);
      } else {
        setSelectedRows(selectedRows.filter(x => x !== row.id));
      }
    }

  const handleOnSelectAll = (isSelect, rows) => {
      const ids = rows.map(r => r.id);
      console.log(isSelect,ids);
      if (isSelect) {
        setSelectedRows(ids);
      } else {
        setSelectedRows([]);
      }
    }


  const unsubscribeToPendingPayoutsData = () => {
    firebase.database().ref('go').off('value');
  }
  const onSubmitCreateEC = async (data) => {
    console.log(data);
    try {
      var createECinPipedriveCallable = firebase
        .functions()
        .httpsCallable('createECinPipedriveCallable');
      var createECinPipedriveCallableResponse = await createECinPipedriveCallable({name: data.name, email: data.email});
      console.log('createECinPipedriveCallableResponse = '+JSON.stringify(createECinPipedriveCallableResponse));
    } catch (e) {
      console.log('createECinPipedriveCallable error');
      console.log(e.message);

    } finally {
      console.log('createECinPipedriveCallable finally');

    }
  }
  const MyExportCSV = (props) => {
    const handleClickedExportSelectedRows = () => {
      var rowsToExport = prepareForExportCSV((selectedRows && _.isArray(selectedRows) && selectedRows.length > 0 ? selectedRows : users.map(row => row.id)));
      console.log('export rows ');
      console.log(rowsToExport);
      // props.onExport();
    };
    return (
      <div>
        <button className="btn btn-primary" onClick={ handleClickedExportSelectedRows }><ButtonIcon icon="file-export"></ButtonIcon>Export {(selectedRows && _.isArray(selectedRows) && selectedRows.length === 1 ? '1 payout' : (selectedRows && _.isArray(selectedRows) && selectedRows.length > 1 ? selectedRows.length + ' payouts' : 'all payouts'))} as CSV</button>
      </div>
    );
  };

  const prepareForExportCSV = (data) => {
    return data;

  }


    const renderMultiSelectActionButtons = () => (
      <>
      <div style={{alignSelf: 'center', marginRight: 12}}>With selected payments: </div>
        <OverlayTrigger
          key='send-payment-key'
          placement='bottom'
          overlay={
            <Tooltip id={`tooltip-bottom`}>
              Send payments for selected payouts via Stripe
            </Tooltip>
          }
        >
          <ActionButton variant="success" onClick={() => { handleClickedSendPaymentForSelectedRows(); }} disabled={(selectedRows && _.isArray(selectedRows) && selectedRows.length > 0 ? '' : 'disabled')}><ButtonIcon icon="file-invoice-dollar" /> Send $</ActionButton>
        </OverlayTrigger>
        <OverlayTrigger
          key='edit-payment-key'
          placement='bottom'
          overlay={
            <Tooltip id={`tooltip-bottom`}>
              Edit payments for selected payouts
            </Tooltip>
          }
        >
          <ActionButton variant="secondary" onClick={() => { handleClickedEditPaymentForSelectedRows(); }} disabled={(selectedRows && _.isArray(selectedRows) && selectedRows.length === 1 ? '' : 'disabled')}><ButtonIcon icon="pen" /> Edit</ActionButton>
        </OverlayTrigger>
        <OverlayTrigger
          key='cancel-payment-key'
          placement='bottom'
          overlay={
            <Tooltip id={`tooltip-bottom`}>
              Delete pending payments for selected payouts
            </Tooltip>
          }
        >
          <ActionButton variant="danger" onClick={() => { handleClickedDeletePaymentForSelectedRows(); }} disabled={(selectedRows && _.isArray(selectedRows) && selectedRows.length > 0 ? '' : 'disabled')}><ButtonIcon icon="trash-alt" /> Delete</ActionButton>
        </OverlayTrigger>
      </>
    )


      const renderModals = () => {
        if (!showConfirmModal) {
          return null;
        }
        if (paymentIDsToSend && _.isArray(paymentIDsToSend) && paymentIDsToSend.length > 0) {
          return renderSendPaymentModal();
        }
        if (paymentIDsToEdit && _.isArray(paymentIDsToEdit) && paymentIDsToEdit.length > 0) {
          return renderEditPaymentModal();
        }
        if (paymentIDsToDelete && _.isArray(paymentIDsToDelete) && paymentIDsToDelete.length > 0) {
          return renderDeletePaymentModal();
        }
      }


        const renderSendPaymentModal = () => {
          var payments = [];
          if (paymentIDsToSend && _.isArray(paymentIDsToSend) && paymentIDsToSend.length > 0) {
            payments = rowsForIds(paymentIDsToSend);
          }
          var payments = rowsForIds(paymentIDsToSend);

          if (!(payments && _.isArray(payments) && payments.length > 0)) {
            return (
              <Modal show={showConfirmModal} onHide={handleCloseConfirmModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>There was an error retrieving the payments</Modal.Body>
                <Modal.Footer>
                  <ActionButton variant="secondary" onClick={handleCloseConfirmModal}>
                    Close
                  </ActionButton>
                </Modal.Footer>
              </Modal>
            );
          }
        }


            const renderEditPaymentModal = () => (
              <Modal show={showConfirmModal} onHide={handleCloseConfirmModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Edit payment{paymentIDsToEdit && _.isArray(paymentIDsToEdit) && paymentIDsToEdit.length > 1 ? 's' : ''}?</Modal.Title>
                </Modal.Header>
                <Modal.Body>Would you like to edit the payment {paymentIDsToEdit}?</Modal.Body>
                <Modal.Footer>
                  <ActionButton variant="danger" onClick={handleCloseConfirmModal}>
                    Cancel
                  </ActionButton>
                  <ActionButton variant="secondary" onClick={handleConfirmEditPayment}>
                    Edit Payment
                  </ActionButton>
                </Modal.Footer>
              </Modal>
            );

            const renderDeletePaymentModal = () => (
              <Modal show={showConfirmModal} onHide={handleCloseConfirmModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Send payment{paymentIDsToDelete && _.isArray(paymentIDsToDelete) && paymentIDsToDelete.length > 1 ? 's' : ''}?</Modal.Title>
                </Modal.Header>
                <Modal.Body>Would you like to delete the payment to {paymentIDsToDelete}?<br/><br/><h3>This cannot be un-done.</h3></Modal.Body>
                <Modal.Footer>
                  <ActionButton variant="secondary" onClick={handleCloseConfirmModal}>
                    Cancel
                  </ActionButton>
                  <ActionButton variant="danger" onClick={handleConfirmDeletePayment}>
                    Delete Payment
                  </ActionButton>
                </Modal.Footer>
              </Modal>
            );
            const rowsForIds = (ids) => {
              var payouts = users.filter ( row => ids.includes(row.id) );
              if (payouts && _.isArray(payouts) && payouts.length > 0) {
                console.log('rowsForIds('+JSON.stringify(ids)+')');
                console.info(payouts);
                return payouts;
              }
              return [];
            }



              const handleConfirmSendPayment = () => {
                console.log('send payments for '+JSON.stringify(paymentIDsToSend));
                setPaymentIDsToSend([]);
                handleCloseConfirmModal();
              }

              const handleConfirmEditPayment = () => {
                console.log('edit payments for '+JSON.stringify(paymentIDsToEdit));
                setPaymentIDsToEdit([]);
                handleCloseConfirmModal();
              }

              const handleConfirmDeletePayment = () => {
                console.log('send payments for '+JSON.stringify(paymentIDsToDelete));
                setPaymentIDsToDelete([]);
                handleCloseConfirmModal();
              }
              const selectRow = {
                mode: 'checkbox',
                clickToSelect: false,
                selected: selectedRows,
                onSelect: handleOnSelect,
                onSelectAll: handleOnSelectAll
              };

  return (
    <ToolkitProvider
      keyField="data_key"
      data={ users }
      columns={ columns }
      exportCSV={ {
        fileName: 'custom.csv',
        separator: '|',
        ignoreHeader: true,
        noAutoBOM: false
      } }
    >
      {
        props =>
        (
          <TableWrapper>
            <BootstrapTable width='auto' keyField='id' data={users} columns={columns} />
              {
                renderModals()
              }
          </TableWrapper>
        )
      }
    </ToolkitProvider>
  )
}

const TableWrapper = styled.div`
  margin: 100px auto;
  padding: 20px;
  .react-bootstrap-table .table {
    width: auto;
    margin: 0px auto;
  }
`;

const CurrentHoverAction = styled.div`
  opacity: 0;
  &:hover {
    opacity: 1;
  }
`;

const SelectedItemsActionsWrapper = styled.div`
display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 40px 40px 8px;
`;

const ActionButton = styled(Button)`
  margin: 0px 4px;
`;

const ButtonIcon = styled(FontAwesomeIcon)`
margin: 0px 4px;
`;

export default AdminEC1Rotation;

import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import firebase from "../../config/firebase.js";
import { getSearchParameters } from "../../utils/url.js"
import { useForm } from 'react-hook-form';
import _ from "lodash";

import "./UserIntakeForm.min.css";

function UserIntakeForm(props) {
  const { register, handleSubmit, errors } = useForm(); // initialize the hook
  const [intakeStep, setIntakeStep] = useState(1);
  const [formType, setFormType] = useState('quote');
  const [loading, setLoading] = useState(false);
  const availableFormTypes = ['quote', 'advocate'];
  const [submitButtonText, setSubmitButtonText] = useState('Get Free Quote');

  useEffect(() => {
    console.log('');
    console.log('');
    console.log('');
    console.log('props');
    console.log(props);
    if (props && _.isObject(props) && _.isString(props.formType) && availableFormTypes.includes(props.formType.toLowerCase())) {
      setFormType(props.formType.toLowerCase());
      if (props.formType.toLowerCase() === 'advocate') {
        setSubmitButtonText('Become an Advocate');
      }
    }
  }, [props.formType]);

  const maxStep = () => {
    switch (formType) {
      case 'advocate':
        return 2;
      case 'quote':
      default:
        return 2;
    }
  }

  const createUserSource = () => {
    switch (formType) {
      case 'advocate':
        return 'advocate';
      case 'quote':
      default:
        return 'quote';
    }
  }

  const onSubmit = (data) => {
    if (intakeStep >= maxStep()) {
      console.log(data);
      createUser(data);
    } else {
      const newStep = intakeStep + 1;
      setIntakeStep(newStep);
    }
  };
  const stepBack = () => {
    console.log('go back');
    setIntakeStep(Math.max(0,intakeStep - 1));
  }

  const createUser = async (formData) => {
    setLoading(true);
    var createQuoteCallable = firebase
          .functions()
          .httpsCallable('createQuoteCallable');
    var data = Object.assign({}, formData, {
      is_company_lead: true,
      activated: false,
      send_create_account: false,
      source: createUserSource(),
      type: 'SA',
    });
    // if (placeDetails && placeDetails.lat) {
    //   data.lat = placeDetails.lat;
    // }
    // if (placeDetails && placeDetails.lng) {
    //   data.lng = placeDetails.lng;
    // }
    // if (data.state === null && placeDetails && placeDetails.state) {
    //   data.state = placeDetails.state;
    // }
    // if (data.zip === null && placeDetails && placeDetails.zip) {
    //   data.zip = placeDetails.zip;
    // }
    // if (
    //   (data.place_id === null || data.place_id.length === 0) &&
    //   placeDetails &&
    //   placeDetails.place_id
    // ) {
    //   data.place_id = placeDetails.place_id;
    // }
    //
    console.log('******** createUserCallable *******');
    console.log(data);

    var createQuoteResponse = await createQuoteCallable(data);
    setLoading(false);
    if (
      createQuoteResponse.data.success === 'false' ||
      createQuoteResponse.data.success === false
    ) {
      if (createQuoteResponse.data.error && createQuoteResponse.data.error.code) {
        // switch (createQuoteResponse.data.error.code) {
        //   case 'auth/email-already-exists':
        //   case 'auth/email-already-in-use':
        //     show({
        //       message: 'That email address is already in use!',
        //       type: 'error',
        //     });
        //     break;
        //
        //   case 'auth/invalid-email':
        //     show({
        //       message: 'That email address is invalid.',
        //       type: 'error',
        //     });
        //     break;
        //
        //   case 'auth/weak-password':
        //     show({
        //       message: 'The provided password is weak.',
        //       type: 'error',
        //     });
        //     break;
        //
        //   default:
        //     show({
        //       message: 'Somthing went wrong, please try again.',
        //       type: 'error',
        //     });
        //     break;
        // }
      } else {
      //   show({
      //     message: 'Somthing went wrong, please try again.',
      //     type: 'error',
      //   });
      }
      // setIsSubmittingForm(false);
      setLoading(false);
    } else {

    }
  }

  return (
    <div className="wrapper invite-wrapper">
      <section className="sectionWrapper">
        <h1>Thanks for visiting us online</h1>
        <h3>To receive your FREE Home Solar and Design Savings, please click the "{submitButtonText}" button & let us help you get your Electric Bill to Zero!</h3>
        <p style={{fontSize: 12, lineHeight:1.2,marginTop:20}}>Your information is private and only for contact purposes by Ion Solar Pros. Your information will not be sold or shared with any other entity or person.</p>

      </section>

      <div className="invite-intake-form">
        <form autoComplete="on" onSubmit={handleSubmit(onSubmit)}>
          <div className={"form-slider on-slide-"+(intakeStep - 1)}>
            <div className="form-slide slide-1">
              <div className="form-field-name">
                <label for="name">Name:</label>
                <input id="name" name="name" autoComplete="name" placeholder="Your name" type="text" ref={register} />
              </div>
              <div className="form-field-email">
                <label for="email">Email:</label>
                <input id="email" name="email" autoComplete="email" type="email" placeholder="email@email.com" ref={register({
                          required: true,
                          pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        })} />
              </div>
              <div className="form-field-phone">
                <label for="phone">Phone:</label>
                <input id="phone" name="phone" autoComplete="tel" type="tel" placeholder="000-000-0000" ref={register({ required: true, maxLength: 11, minLength: 8 })} />
              </div>
            </div>
            <div className="form-slide slide-2">
              { formType === 'quote' && (
              <h5 style={{textAlign: 'center', width: '100%'}}>So we can give you an accurate quote, please enter your address details</h5>
              )}
              <div className="form-field-street">
                <label for="street">Street:</label>
                <input id="street" name="street" autoComplete="street-address" type="text" placeholder="123 Main" ref={register} />
              </div>
              <div className="form-field-city">
                <label for="city">City:</label>
                <input id="city" name="city" autoComplete="address-level2" type="text" placeholder="City" ref={register} />
              </div>
              <div className="form-field-state">
                <label for="state">State:</label>
                <input id="state" name="state" autoComplete="address-level1" type="text" placeholder="State" ref={register} />
              </div>
              <div className="form-field-zip">
                <label for="zip">Zip:</label>
                <input id="zip" name="zip" autoComplete="postal-code" type="text" placeholder="Zip" ref={register({ required: true, maxLength: 5, minLength: 5 })} />
              </div>
            </div>
          </div>
        {errors.zip && 'Valid zip is required.'}
        {errors.email && 'Valid email is required.'}
          <input type="submit" value={submitButtonText}/>
          { false && intakeStep > 1 && (
            <button onClick={stepBack} style={{backgroundColor: 'transparent', color: '#ffffff', padding: 8, marginLeft: -8}}>Back</button>
          )}
        </form>
      </div>
  </div>
  )
}

export default UserIntakeForm;

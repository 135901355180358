import React, { useState, useEffect } from "react";
import { NavLink, Link, useHistory } from "react-router-dom";
import firebase from "../../config/firebase.js";
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import _ from "lodash";


function Login(props) {
  const { register, handleSubmit, errors, reset, watch, setError } = useForm(); // initialize the hook
  const [formStep, setFormStep] = useState(1);
  const [formType, setFormType] = useState('quote');
  const [isIframe, setIsIframe] = useState(false);
  const [defaultName, setDefaultName] = useState(null);
  const [defaultEmail, setDefaultEmail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [referrerId, setReferrerId] = useState(null);
  const [createAccountReferrer, setCreateAccountReferrer] = useState(null);
  const [createAccountReferred, setCreateAccountReferred] = useState(null);
  const [createAccountCreateNewUserSelection, setCreateAccountCreateNewUserSelection] = useState(false);
  const [response, setResponse] = useState(null);
  const [redirectSuccess, setRedirectSuccess] = useState(false);

  const history = useHistory();

  const FORM_RESPONSE_SHOW_TIME = 8; // how long the form success message shows for

  useEffect(() => {
    console.log('');
    console.log('');
    console.log('');
    console.log('props');
    console.log(props);
  }, []);



  const onSubmitLogin = async (data) => {
    console.log('onSubmitLogin');
    console.log(data);
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(() => {
        return firebase.auth().signInWithEmailAndPassword( data.email, data.password)
        .then((userCredential) => {
          if (props && props.location && props.location.state && props.location.state.from && _.isString(props.location.state.from) && props.location.state.from !== '') {
            props.history.push(props.location.state.from);
          } else {
            props.history.push('dashboard');
          }
        })
        .catch((error) => {
          window.alert(error.message);
        });
      })
      .catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
      });


  };

      return (
      <LoginForm className={(isIframe ? "iframe" : "")}>
        <form autoComplete="on" onSubmit={handleSubmit(onSubmitLogin)}>
          <div className={"form-slider on-slide-"+(formStep - 1)}>
            <div className="form-slide slide-1">
              <div className="form-field-email">
                <label htmlFor="email">Email:</label>
                <input defaultValue={defaultEmail || ''} id="email" name="email" autoComplete="email" type="email" placeholder="email@email.com" ref={register({
                          required: true,
                          pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        })} />
              </div>
              <div className="form-field-password">
                <label htmlFor="password">Password:</label>
                <input id="password" name="password" autoComplete="password" type="password" placeholder="Enter password" ref={register({ required: true })} />
              </div>
            </div>
          </div>
          { response === null && (
            <>
        {errors.email && 'Please enter your email.'}
        {errors.password && 'Please enter your password.'}
          </>
      )}
          <input type="submit" value="Login"/>
        </form>
      </LoginForm>
    )

}

export default Login;

const LoginForm = styled.div`
  z-index: 1;
  min-height: 400px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 100px;
  margin-left: -15px;
  margin-right: -15px;
  & > form {
    display: flex;
    flex-flow: column;
    flex-wrap: wrap;
    overflow: hidden;
    & > .form-slider {
      max-width: 100%;
      display: flex;
      flex-flow: row;
      flex-wrap: nowrap;
      position: relative;
      transition: left .75s;
      align-items: flex-start;
      & > .form-slide {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        width: 100%;
        flex-basis: 100%;
        min-width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        & >  div {
          display: flex;
          flex-flow: column;
          flex-basis: 100%;
          margin: 8px 0;
          &.form-field-phone {
            max-width: 120px;
            margin-bottom: 16px;
          }
          &.form-field-city {
            flex-basis: calc(45% - 8px);
          }
          &.form-field-state {
            margin: 8px;
            flex-basis: calc(30% - 8px);
            min-width: 75px;
          }
          &.form-field-zip {
            flex-basis: 25%;
            min-width: 50px;
          }
        }
      }
    }
    input {
      flex-basis: 100%;
      padding: 14px;
      border-radius: 4px;
      border: none;
      background: #efefef;
      &[type="submit"] {
        background: rgb(251, 149, 16);
        font-weight: 600;
        color: #ffffff;
        font-size: 1.5em;
        margin-top: 8px;
        margin-left: 15px;
        margin-right: 15px;
        cursor: pointer;
      }
      &:focus {
        outline: none;
      }

    }
    .form-response {
      margin: 8px 15px 0;
      font-weight: 600;
      &.form-response-error {
        color: red;
      }
      &.form-response-success {
        color: green;
      }
    }
    label {
      color: white;
      font-weight: 400;
      display: block;
    }
    button {
      &:focus, &:active {
        outline: none;
        border: none;
      }
    }
  }
`;

const TermsLink = styled.a`
  color: #fb9510;
  text-decoration: none !important;
`;

const TermsWrapper = styled.div`
  flex-flow: row !important;
  align-items: center;
`;

const TermsCheckbox = styled.input`
  flex: 0;
  flex-basis: 0 !important;
`;
const TermsLabel = styled.label`
  margin-left: 8px;
`;

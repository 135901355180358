import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import HowItWorks from "../HowItWorks/HowItWorks";
import UserIntakeForm from "../UserIntakeForm/UserIntakeForm";
import BlogDetails from "../BlogDetails/BlogDetails";
import firebase from "../../config/firebase.js";
import { getSearchParameters, getPathRouteArray } from "../../utils/url.js"
import _ from "lodash";

import "./Invite.min.css";

function Invite(props) {
  const [referrer, setReferrer] = useState(null);
  const [blogPostId, setBlogPostId] = useState(null);
  const [formType, setFormType] = useState('quote');
  const path = ((props.location.pathname || '').replace('/invite/','').replace('/get-quote/','').replace('/become-an-advocate/','').split('/')[0] || '');
  useEffect(() => {
    getReferralData(((props.location.pathname || '').replace('/invite/','').replace('/get-quote/','').replace('/become-an-advocate/','').split('/')[0] || ''));
    loadBlogParams(props.location);
    if (props.location.pathname.includes('become-an-advocate')) {
      console.log('advocate');
      setFormType('advocate');
    }
  }, [props.location]);

  const loadBlogParams = (location) => {
    const params = getSearchParameters(props.location);
    if (params && _.isObject(params) && params.p !== null && (_.isInteger(params.p) || (_.isString(params.p) && params.p !== '' && !isNaN(params.p)))) {
      setBlogPostId(parseInt(params.p));
    } else {
      const routeArray = getPathRouteArray(props.location);
      if (_.isArray(routeArray) && routeArray.length > 0) {
        if (routeArray[0] === 'get-quote' || routeArray[0] === 'become-an-advocate') {
          if (routeArray.length >= 3) {
            if (!isNaN(routeArray[2])) {
              setBlogPostId(parseInt(routeArray[2]));
            }
          }
        }
      }
    }
  }

  const getReferralData = async (invite_code) => {
    console.log(invite_code);
    if (invite_code && invite_code !== '') {
      var userIDRef = firebase.database().ref('referrals').child(invite_code);
      userIDRef.on('value', (userIDSnapshot) => {
        if (userIDSnapshot.exists()) {
          const user_id = userIDSnapshot.val();
          if (user_id && _.isString(user_id) && user_id !== '') {
            var userRef = firebase.database().ref('teams').child(user_id);
            userRef.on('value', (userSnapshot) => {
              if (userSnapshot.exists()) {
                const user = userSnapshot.val();
                if (user && _.isObject(user)) {
                  var gotData = false;
                  var referred = {code: invite_code, u_id: userSnapshot.key};
                  var referredFields = ['name', 'email'];
                  referredFields.forEach((referredField, i) => {
                    if (user[referredField] && user[referredField] !== '') {
                      referred[referredField] = user[referredField];
                      gotData = true;
                    }
                  });
                  if (gotData) {
                    setReferrer(referred);
                    if (invite_code !== path) {
                      props.history.push('/invite/'+invite_code)
                    }
                  } else {
                    if (invite_code.length > 5) {
                      getReferralData(invite_code.substring(0,5));
                    } else {
                      setReferrer({valid: false});
                    }
                  }
                } else {
                  if (invite_code.length > 5) {
                    getReferralData(invite_code.substring(0,5));
                  } else {
                    setReferrer({valid: false});
                  }
                }
              } else {
                if (invite_code.length > 5) {
                  getReferralData(invite_code.substring(0,5));
                } else {
                  setReferrer({valid: false});
                }
              }
            });
          } else {
            if (invite_code.length > 5) {
              getReferralData(invite_code.substring(0,5));
            } else {
              setReferrer({valid: false});
            }
          }
        } else {
          if (invite_code.length > 5) {
            getReferralData(invite_code.substring(0,5));
          } else {
            setReferrer({valid: false});
          }
        }
      });
    } else {
      setReferrer(null);
    }
  }

  const renderHeader = () => {
    return (
      <header>
        <div className="wrapper">
          <NavLink to='/'>
            <img src="/assets/logo.png" alt="logo" />
          </NavLink>
        </div>
      </header>
    )
  }

    return (
      <>
      {console.log('render')}
      { (blogPostId !== null) ? (
        <>
          <div id="content" className="slim">
            { renderHeader() }
          </div>
          <BlogDetails postId={blogPostId} />
            <div id="content" className="panels" style={{paddingTop: '50px', paddingBottom: '100px'}}>
          <UserIntakeForm formType={formType} referrer={(referrer !== null && _.isObject(referrer) && referrer.valid !== false) ? referrer : null} />
        </div>
        </>
      ) : (
        <>
          <div id="content" className="panels">
            { renderHeader() }
            <UserIntakeForm formType={formType} referrer={(referrer !== null && _.isObject(referrer) && referrer.valid !== false) ? referrer : null}/>
          </div>
        </>
      )}
      </>
    );
}

export default Invite;

import React from "react";
import "./HowItWorks.css";

function HowItWorks() {

  return (
    <>
      <div className="wrapper">
        <div id="usage">
          <h2>How it works</h2>
          <ol>
            <li>
              <img src="/assets/download.png" alt="Downlaod" />
              <strong>Download the app</strong>
              <p>Download the app, enter your referral code and register to the program.</p>
            </li>
            <li>
              <img src="/assets/users.png" alt="Users" />
              <strong>Manage your team</strong>
              <p>Build your team and manage them right from the app to create leads.</p>
            </li>
            <li>
              <img src="/assets/dollar-sign.png" alt="Dollar sign" />
              <strong>Get paid for your leads</strong>
              <p>Get paid for the leads you generate through your team.</p>
            </li>
          </ol>
        </div>
      </div>
    </>
  );
}

export default HowItWorks;

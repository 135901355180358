import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import firebase from "../../config/firebase.js";
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUser, faUsers, faPen, faTrashAlt, faFileExport, faFunnelDollar } from "@fortawesome/free-solid-svg-icons";
import ListGroup from 'react-bootstrap/ListGroup'
import { QRCode } from 'react-qrcode-logo';
import ScaleLoader from "react-spinners/ScaleLoader";

import _ from "lodash";

import "./QRCodeForUser.min.css";

library.add(faUser, faUsers, faPen, faTrashAlt, faFileExport, faFunnelDollar);

const { ExportCSVButton } = CSVExport;
const { SearchBar } = Search;

const TEST_LOCAL = false;

function QRCodeForUser(props) {
  const { register, handleSubmit, watch } = useForm();
  const [url, setUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [referralCode, setReferralCode] = useState('');
  const [userName, setUserName] = useState('');

  const source = watch('source');
  const campaign = watch('campaign');
  const blogId = watch('blogId');
  const extraValue1 = watch('extraValue1');
  const extraValue2 = watch('extraValue2');
  const extraValue3 = watch('extraValue3');
  const extraKey1 = watch('extraKey1');
  const extraKey2 = watch('extraKey2');
  const extraKey3 = watch('extraKey3');


  useEffect(() => {
    console.log(props.match.params);
    if (_.isString((((props || {}).match || {}).params || {}).code) && props.match.params.code !== '') {
      setReferralCode(props.match.params.code);
    } else {
      if (_.isString((((props || {}).match || {}).params || {}).userID) && props.match.params.userID !== '') {
        setLoading(true);
        firebase.database().ref('users').child(props.match.params.userID).child('referral_code').once('value', (snapshot) => {
          if (snapshot.exists() && _.isString(snapshot.val()) && snapshot.val() !== '') {
            setReferralCode(snapshot.val());
            setLoading(false);
          } else {
            console.error('an error occurred');
            console.log(snapshot.val());
            alert(`An error occurred. Most likely this userID '${props.match.params.userID}' was not found in the database`);
          }
        })
      }
    }

    if (_.isString((((props || {}).match || {}).params || {}).userID) && props.match.params.userID !== '') {
      firebase.database().ref('teams').child(props.match.params.userID).child('name').once('value', (snapshot) => {
        if (snapshot.exists() && _.isString(snapshot.val()) && snapshot.val() !== '') {
          setUserName(snapshot.val());
        }
      });
    }
    // try {
    //   if (_.isString(props.userType) && props.userType !== '') {
    //     loadUsersData(props.userType);
    //   } else {
    //     loadUsersData();
    //   }
    // } catch (e) {
    //   console.log('got an error loading data');
    //   console.error(e.message);
    // } finally {
    //   console.log('finally done with useEffect');
    // }
  }, [((props || {}).match || {}).params]);

  useEffect(() => {
    // console.log({source, campaign, extraKey1, extraValue1, extraKey2, extraValue2, extraKey3, extraValue3 });
    var params = {};
    if (!(_.isNil(source) || _.isEmpty(source) || source === '')) {
      params.source = source;
    }
    if (!(_.isNil(campaign) || _.isEmpty(campaign) || campaign === '')) {
      params.campaign = campaign;
    }
    if (!(_.isNil(extraKey1) || _.isEmpty(extraKey1) || extraKey1 === '' || _.isNil(extraValue1) || _.isEmpty(extraValue1) || extraValue1 === '')) {
      params[extraKey1] = extraValue1;
    }
    if (!(_.isNil(extraKey2) || _.isEmpty(extraKey2) || extraKey2 === '' || _.isNil(extraValue2) || _.isEmpty(extraValue2) || extraValue2 === '')) {
      params[extraKey2] = extraValue2;
    }
    if (!(_.isNil(extraKey3) || _.isEmpty(extraKey3) || extraKey3 === '' || _.isNil(extraValue3) || _.isEmpty(extraValue3) || extraValue3 === '')) {
      params[extraKey3] = extraValue3;
    }
    if ((_.isFinite(blogId) || !isNaN(blogId)) && parseInt(blogId) > 0) {
      params.p = blogId;
    }


    var pArray = [];
    var paramString = '';
    for(var p in params) {
       pArray.push(encodeURIComponent(p) + "=" + encodeURIComponent(params[p]));
     }
    if (_.isArray(pArray) && pArray.length > 0) {
      paramString = `?${pArray.join('&')}`;
    }

    var newUrl = `https://app.getpowerlink.com/invite/${referralCode}${paramString}`;

    if (newUrl !== url) {
      setUrl(newUrl);
      console.log(`setUrl(${newUrl})`);
    } else {
      console.log('same for '+newUrl);
    }

    // console.log(`https://app.getpowerlink.com/invite/${referralCode}${paramString}`);




  }, [source, campaign, extraValue1, extraValue2, extraValue3, extraKey1, extraKey2, extraKey3, referralCode, blogId]);


  // const unsubscribeToUsersData = () => {
  //   firebase.database().ref('users').off('value');
  // }


  const onSubmit = () => {

  }


  return (
    <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
      {(_.isString(userName) && userName !== '') && (
        <h3>{userName}</h3>
      )}
      <QRSubTitle>Right-click on the image to save it</QRSubTitle>
    <QRWrapper>
      <QRCode value={url} logoImage="/assets/AppIcon.png" size="400"/>
      {(loading || _.isNil(referralCode) || referralCode === '') && (
      <Loader>
        <ScaleLoader color="#ffffff" loading={(loading || _.isNil(referralCode) || referralCode === '')} height={45} width={4} radios={2} margin={2} />
      </Loader>
    )}
    </QRWrapper>
    <a href={url} target="_blank">{url}</a>
    <FormSubTitle>Optional tracking info:</FormSubTitle>
    <form autoComplete="new-password" onSubmit={handleSubmit(onSubmit)} >
      <div className="form-field form-field-blog-id">
        <label htmlFor="blogId">Blog Post ID:</label>
        <input id="blogId" name="blogId" autoComplete="new-password" type="text" placeholder="i.e. 1234" ref={register} />
      </div>
      <div className="form-field form-field-source">
        <label htmlFor="source">Source:</label>
        <input id="source" name="source" autoComplete="new-password" type="text" placeholder="ie. Direct Advantage" ref={register} />
      </div>
      <div className="form-field form-field-campaign">
        <label htmlFor="campaign">Campaign:</label>
        <input id="campaign" name="campaign" autoComplete="new-password" type="text" placeholder="i.e. DA0821" ref={register} />
      </div>
      <div className="form-field form-field-extra1">
        <input id="extraKey1" name="extraKey1" className="custom-key" autoComplete="new-password" type="text" placeholder="Key" ref={register} />
        <input id="extraValue1" name="extraValue1" autoComplete="new-password" type="text" placeholder="Value" ref={register} />
      </div>
      <div className="form-field form-field-extra2">
        <input id="extraKey2" name="extraKey2" className="custom-key" autoComplete="new-password" type="text" placeholder="Key" ref={register} />
        <input id="extraValue2" name="extraValue2" autoComplete="new-password" type="text" placeholder="Value" ref={register} />
      </div>
      <div className="form-field form-field-extra3">
        <input id="extraKey3" name="extraKey3" className="custom-key" autoComplete="new-password" type="text" placeholder="Key" ref={register} />
        <input id="extraValue3" name="extraValue3" autoComplete="new-password" type="text" placeholder="Value" ref={register} />
      </div>
    </form>
  </div>
  )
}

const FormSubTitle = styled.p`
  margin-bottom: 0px !important;
  margin-top: 30px;
`;
const QRSubTitle = styled.p`
  margin-bottom: 0px !important;
`;
const QRWrapper = styled.div`
  display: flex;
  max-width: 420px;
`;

const Loader = styled.div`
  flex: 1;
  margin-left: -100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0,0.95);
  z-index: 2;
`;

const SelectedItemsActionsWrapper = styled.div`
display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 40px 40px 8px;
`;

const ActionButton = styled(Button)`
  margin: 0px 4px;
`;

const ButtonIcon = styled(FontAwesomeIcon)`
margin: 0px 4px;
`;

export default QRCodeForUser;
